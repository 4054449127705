import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import './Icon.scss';
import { SvgIcon } from './IconList';

interface Props {
	type: 'font' | 'svg';
	classes?: string;
	icon?: SvgIcon;
	alt?: string;
	title?: string;
	size: IconSizeUnion;
	use?: 'decorative' | 'functional';
	onClick?: () => void;
}

export type IconSizeUnion = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export function Icon({
											 type,
											 use = 'decorative',
											 classes,
											 icon,
											 alt,
											 title,
											 size,
											 onClick,
										 }: Props) {

	switch (type) {
		case 'font':
			console.error(`font-based icons not implemented`, icon);
			return null;
		case 'svg':
			if (icon?.element === 'svg') {
				return (
					<span
						onClick={() => {
							if (onClick) {
								onClick()
							}}
						}
						className={clsx('ske-icon svg-icon', classes, size)}>
						<SVG
							role={use === 'decorative' ? 'presentation' : 'img'}
							height="100%"
							src={icon.src}
							className={classes}>
							{use === 'functional' && title && (
								<title>{title}</title>
							)}
							{use === 'functional' && alt && (
								<desc>{alt}</desc>
							)}
						</SVG>
				</span>
				);
			} else if (icon?.element === 'img') {
				return (
					<>
						<img
							onClick={() => {
								if (onClick) {
									onClick()
								}}
							}
							className={clsx('ske-icon', classes)}
							width={size}
							aria-hidden="true"
							role={use === 'decorative' ? 'presentation' : 'img'}
							src={icon.src}
							alt={alt}
							title={title}
						/>
					</>
				);
			}
			console.error(`Invalid configuration for svg icon`, icon);
			return null;
		default:
			console.error(`Unknown configuration for icon`, icon);
			return null;
	}
}

