import { SyntheticEvent } from 'react';
import { toAbsoluteUrl } from '../../_library/helpers';
import clsx from "clsx";

interface Props {
	className?: string;
	url: string | null | undefined;
	size?: AvatarSize;
	alt: string;
}

export enum AvatarSize {
	sm = 12,
	md = 24,
	lg = 48,
	xl = 72,
}

export function Avatar({ className, url, size = AvatarSize.lg, alt }: Props) {
	const getSize = () => {
		switch (size) {
			case AvatarSize.sm:
				return 12;
			case AvatarSize.md:
				return 24;
			case AvatarSize.lg:
				return 48;
			case AvatarSize.xl:
				return 72;
		}
	}

	const getUrlOrFallback = (src: string | null | undefined) => {
		if (!src || src.includes('media/icons/duotune/general/create-team-icon')) {
			return toAbsoluteUrl('/media/avatars/blank.png');
		}
		return src;
	}

	return (
			<img
				className={clsx('rounded-circle avatar', className)}
				src={getUrlOrFallback(url)}
				alt='Avatar'
				aria-describedby={alt}
				width={getSize()}
				height={getSize()}
				onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
					const target = e.currentTarget;
					target.onerror = null;
					target.src = toAbsoluteUrl('/media/avatars/blank.png');
				}}
			/>
	)
}
