import { useState } from 'react';
import { SortOrderEnum } from '../../app/types/SortOrderEnum';

interface ButtonSortableProps {
	label: string;
	isSorted?: boolean;
	sortOrder?: SortOrderEnum;
	onSort?: () => void;
}

interface SortState<T> {
	field: T;
	order: SortOrderEnum;
}

export function ButtonSortable({
																 label,
																 isSorted,
																 sortOrder,
																 onSort,
															 }: ButtonSortableProps) {
	return (
		<button
			style={{fontWeight: 'inherit'}}
			className="p-0 text-reset"
			type="button"
			onClick={() => onSort?.()}
		>
			{isSorted && (
				<>
          <span>
            {label}
						<span className="ms-3">
              {sortOrder === SortOrderEnum.Desc && <span>↓&nbsp;&nbsp;</span>}
							{sortOrder === SortOrderEnum.Asc && <span>&nbsp;&nbsp;↑</span>}
            </span>
          </span>
				</>
			)}
			{(!isSorted || sortOrder === SortOrderEnum.None) && (
				<>
          <span>
            {label}
						{!isSorted && <span className="ms-3">↓↑</span>}
          </span>
				</>
			)}
		</button>
	);
}

export function useButtonSortable<T extends string>(
	initialField: T,
	initialOrder: SortOrderEnum = SortOrderEnum.Asc,
) {
	const [sort, setSort] = useState<SortState<T>>({
		field: initialField,
		order: initialOrder,
	});

	const handleSortChange = (field: T) => {
		setSort((prev) => ({
			field,
			order:
				prev.field === field && prev.order === SortOrderEnum.Desc
					? SortOrderEnum.Asc
					: SortOrderEnum.Desc,
		}));
	};

	const getSortFieldValue = () => {
		return `${sort.order === SortOrderEnum.Desc ? '-' : ''}${sort.field}`;
	};

	return {
		sort,
		handleSortChange,
		getSortFieldValue,
	};
}
