import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {isArray, isUndefined, omit} from 'lodash'
import { Employee } from '../../app/modules/employee/models/Employee.model';
import { ApiResponse, ApiResponseWithoutPagination } from '../../app/modules/shared/models/ApiResponse.model';
import { Team, TeamDetailed } from '../../app/modules/team/models/Team.model';
import { User } from '../../app/modules/user/models/User.model';
import {
	getAndSetBearerToken, SkeBasicAPIResponse, skeResponseBasicErrorHandler,
} from '../../common/api/common.api'
import {
	TeamAssignSupervisorModel,
	TeamCreateApiRequestModel,
	TeamGetEmployeesRequestParamsModel,
	TeamUnassignSupervisorModel,
	TeamUpdateApiRequestModel,
	TeamUploadAvatarApiRequestModel,
	TeamUploadEmployeesSpreadsheetApiRequestModel, TeamGetOneApiRequestModel,
} from './interfaces/team.model';
import {BaseQueryError} from '@reduxjs/toolkit/dist/query/baseQueryTypes'

export enum TeamTagTypes {
	Teams = 'TEAMS',
	Supervisors = 'TEAM_SUPERVISORS',
	Employees = 'TEAM_EMPLOYEES',
}

export const teamSlice = createApi({
	reducerPath: 'teamApi',
	tagTypes: [TeamTagTypes.Teams, TeamTagTypes.Supervisors, TeamTagTypes.Employees],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/teams`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getAllTeams: builder.query<Team[], void>({
			query: () => '',
			// ApiResponse<Team> because it already wraps items as an array of the data type passed in via the <> brackets
			transformResponse: (response: ApiResponse<Team>, meta, arg) => response.items,
			transformErrorResponse: (response: { status: string | number }, meta, arg) => response.status,
			providesTags: (result) => {
				// successful result
				if (result) {
					return [
						...result.map(({ id }) => ({
							type: TeamTagTypes.Teams,
							id,
						} as const)),
						{
							type: TeamTagTypes.Teams,
							id: 'LIST',
						},
					];
				}
				// an error occurred, but still want to refetch this query when `{ type: 'Teams', id: 'LIST' }` is invalidated
				return [
					{
						type: TeamTagTypes.Teams,
						id: 'LIST',
					},
				];
			},
			// TODO: constant for default data to hold
			keepUnusedDataFor: 3600,
		}),
		getOneTeam: builder.query<TeamDetailed, TeamGetOneApiRequestModel>({
			query: (args: { team_id: number, includeDates?: boolean, includeSupervisors?: boolean }) => {
				let url = `${args.team_id}`;
				const params: any = {};
				if (!isUndefined(args.includeDates)) {
					params.includeDates = args.includeDates;
				}
				if (!isUndefined(args.includeSupervisors)) {
					params.includeSupervisors = args.includeSupervisors;
				}
				return {
					url,
					params,
				};
			},
			transformErrorResponse: (response: { status: string | number }, meta, arg) => response.status,
			providesTags: (result, error, id) => {
				if (result) {
					return [
						{
							type: TeamTagTypes.Teams,
							id: result.id,
						},
					];
				}
				return [];
			},
			// TODO: constant for default data to hold
			keepUnusedDataFor: 600,
		}),
		createTeam: builder.mutation<Team, TeamCreateApiRequestModel>({
			query: (args) => ({
				url: '',
				method: 'post',
				body: args,
			}),
			transformResponse:  (response: Team): Team => {

				return response;
			},
			transformErrorResponse(response: BaseQueryError<any>): SkeBasicAPIResponse {
				return skeResponseBasicErrorHandler(response, 'Error creating team');
			},
			// invalidates any Teams-typed queries providing the 'LIST' id, as this could show up in any of those lists
			invalidatesTags: [
				{
					type: TeamTagTypes.Teams,
					id: 'LIST',
				},
			],
		}),
		updateTeam: builder.mutation<Team, TeamUpdateApiRequestModel>({
			query: (args) => ({
				url: `${args.id}`,
				// patch MUST be in uppercase or it's likely to fail https://fetch.spec.whatwg.org/#example-normalization
				method: 'PATCH',
				body: omit(args, 'id'),
			}),
			transformResponse: (response: Team) => response,
			transformErrorResponse: (response: { status: string | number }) => response.status,
			// invalidates both the list of teams (as we just edited a team), and if this one was called specifically,
			// clear it
			invalidatesTags: (result, error, arg) => {
				if (result) {
					return [
						{
							type: TeamTagTypes.Teams,
							id: 'LIST',
						},
						{
							type: TeamTagTypes.Teams,
							id: arg.id,
						},
					];
				}
				return [];
			},
		}),
		assignSupervisor: builder.mutation<Team, TeamAssignSupervisorModel>({
			query: (args) => ({
				url: `${args.team_id}/assign`,
				params: {
					email: args.email,
				},
				method: 'PATCH',
			}),
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: TeamTagTypes.Teams,
						id: 'LIST',
					},
					{
						type: TeamTagTypes.Supervisors,
						id: args.team_id,
					},
				];
			},
		}),
		unassignSupervisor: builder.mutation<Team, TeamUnassignSupervisorModel>({
			query: (args) => ({
				url: `${args.team_id}/unassign`,
				params: {
					email: args.email,
				},
				method: 'PATCH',
			}),
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: TeamTagTypes.Teams,
						id: 'LIST',
					},
					{
						type: TeamTagTypes.Supervisors,
						id: args.team_id,
					},
				];
			},
		}),
		getTeamMembers: builder.query<ApiResponse<Employee>, TeamGetEmployeesRequestParamsModel>({
			query: (args) => {
				const params: { [key: string]: string | boolean | number } = {};
				Object.entries(args).forEach(([key, value]) => {
					if (['page', 'limit', 'isAtRisk', 'isTerminated'].includes(key)) {
						if (!isUndefined(value)) {
							params[key] = value;
						}
					} else if (key === 'sort') {
						params.sort = isArray(value.join(',')) || value;
					}
				});
				return {
					url: `${args.team_id}/employees`,
					params,
				};
			},
			// TODO: hotfix: removing filtering down to items, need to handle pagination response
			// transformResponse: (response: ApiResponse<Employee>) => response.items,
			providesTags: (result, error, arg) => {
				return [
					{
						type: TeamTagTypes.Employees,
						id: arg.team_id,
					},
				];
			},
		}),
		// disabled/not implemented until Foreign Key fix for teams<>connections is deployed
		// TODO: need to test when that's re-enabled
		// deleteTeam: builder.mutation<void, {team_id: number}>({
		// 	query: (args) => ({
		// 		url: `${args.team_id}`,
		// 		method: 'delete',
		// 	}),
		// 	invalidatesTags: (result, error, args) => {
		// 		return [
		// 			{
		// 				type: TeamTagTypes.Teams,
		// 				id: 'LIST',
		// 			},
		// 			{
		// 				type: TeamTagTypes.Teams,
		// 				id: args.team_id,
		// 			},
		// 		];
		// 	},
		// }),
		getSupervisors: builder.query<User[], { team_id: number }>({
			query: (args) => ({
				url: `${args.team_id}/supervisors`,
			}),
			transformResponse: (response: ApiResponse<User>) => response.items,
			providesTags: (result, error, args) => {
				return [
					{
						type: TeamTagTypes.Supervisors,
						id: args.team_id,
					},
				];
			},
		}),
		// appears nothing is returned after success, thus `void`
		uploadAvatar: builder.mutation<void, TeamUploadAvatarApiRequestModel>({
			query: (args) => {
				const body = new FormData();
				body.append('file', args.avatarFile);

				return {
					url: `${args.team_id}/avatar`,
					method: 'post',
					body,
					// was in old code, but errored when I tried it
					// headers: { 'Content-Type': 'multipart/form-data' },
				};
			},
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: TeamTagTypes.Teams,
						id: 'LIST',
					},
					{
						type: TeamTagTypes.Teams,
						id: args.team_id,
					},
				];
			},
		}),
		// appears nothing is returned after success, thus `void`
		importTeamMembers: builder.mutation<void, TeamUploadEmployeesSpreadsheetApiRequestModel>({
			query: (args) => {
				const body = new FormData();
				args.spreadsheetFiles.forEach(file => {
					body.append('file', file);
				});

				return {
					url: `${args.team_id}/import`,
					method: 'post',
					body,
					// was in old code, but errored when I tried it
					// headers: { 'Content-Type': 'multipart/form-data' },
				};
			},
			invalidatesTags: (result, error, args) => {
				return [
					{
						type: TeamTagTypes.Teams,
						id: 'LIST',
					},
					{
						type: TeamTagTypes.Teams,
						id: args.team_id,
					},
					{
						type: TeamTagTypes.Employees,
						id: args.team_id,
					},
				];
			},
		}),
	}),
});
