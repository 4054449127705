import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '../../../../../setup';
import {
    createPerformanceCategory,
    getPerformanceCategories,
    removePerformanceCategory,
    updatePerformanceCategory,
} from '../../../../CRUD/PerformanceCRUD';
import {SkeDialogDelete} from '../../../../../common/components/dialog-delete';
import {SkeDialogTextPrompt} from '../../../../../common/components/dialog-text-prompt';
import {toast} from 'react-toastify';
import {CategoryModel, CategorySubtypeEnum} from '../../../../../features/category/interfaces/category.model';
import {SkeModal} from '../../../../../common/modals/generic/SkeModal';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {capitalizeName} from '../../../../../common/utilities/string.utility';

type Props = {
    categories: CategoryModel[]
    setCategories: (categories: CategoryModel[]) => void
}

export default function CategoriesList({ categories, setCategories }: Props) {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showTextPrompt, setShowTextPrompt] = useState(false);
    const [textPromptConfig, setTextPromptConfig] = useState({ title: '', inputValue: '', onConfirm: (value: string) => {} });
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
    const [showCategoryEditModal, setShowCategoryEditModal] = useState<boolean>(false);
    const [category, setCategory] = useState<CategoryModel>();

    const [formInitialValues, setFormInitialValues] = useState<Pick<CategoryModel, 'id' | 'name' | 'subtype'>>({
        id: 0,
        name: '',
        subtype: CategorySubtypeEnum.Performance
    });

    const handleCreateCategory = () => {
        setFormInitialValues({
            id: 0,
            name: '',
            subtype: CategorySubtypeEnum.Performance
        });
        setShowCategoryEditModal(true);
    };

    const handleEditCategory = (category: CategoryModel) => {
        setCategory(category);
        formik.setValues({
            id: category.id,
            name: category.name,
            subtype: category.subtype
        });
        setShowCategoryEditModal(true);
    };

    const handleOnSubmit = async () => {
        const { id, name, subtype } = formik.values;

        try {
            if (id === 0) {
                await createPerformanceCategory({ name, subtype }, token);
                toast.success('Category created successfully!', {
                    position: 'top-right',
                    theme: 'colored',
                    autoClose: 2000,
                });
            } else {
                await updatePerformanceCategory(id, { name, subtype }, token);
                toast.success('Category updated successfully!', {
                    position: 'top-right',
                    theme: 'colored',
                    autoClose: 2000,
                });
            }
            await refreshData();
            setShowCategoryEditModal(false);

        } catch (error) {
            toast.error('Failed to save category', {
                position: 'top-right',
                theme: 'colored',
                autoClose: 2000,
            });
        }
    };


    const handleUpdateCategory = async (category: Pick<CategoryModel,'id' | 'name' | 'subtype'> )=>{
        try {
            await updatePerformanceCategory(category.id, { name: category.name, subtype: category.subtype }, token);
            setShowTextPrompt(false);
            toast.success("Category updated!", {
                position: "top-right",
                theme: "colored",
                autoClose: 2000,
            });
        } catch (err) {
            setShowTextPrompt(false);
            toast.error("Failed to update category", {
                position: "top-right",
                theme: "colored",
                autoClose: 2000,
            });
        }
        refreshData()
    }

    const handleEditCategoryClose = (action: boolean) =>{
        refreshData()
        setShowCategoryEditModal(action);
    }

    const refreshData = async () => {
        formik.setValues({
            id: 0,
            name: '',
            subtype: CategorySubtypeEnum.Performance
        });
        return getPerformanceCategories(false, token)
          .then(({ data }) => {
              setCategories(data.items)
          })
          .catch(err => console.log(err));
    }

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedCategoryId !== null) {
            removePerformanceCategory(selectedCategoryId, token)
                .then(() => {
                    refreshData()
                    .then(() => {
                        successToast();
                    })
                })
                .catch(err => {
                    console.log(err);
                    errorToast();
                });
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleRemoveCategory = (category: CategoryModel) => {
        setSelectedCategoryId(category.id);
        setShowDeleteDialog(true);
    };

    useEffect(() => {
        let abortController = new AbortController();

        getPerformanceCategories(false, token)
            .then(({ data }) => {
                setCategories(data.items)
            })
            .catch(err => console.log(err))

        return () => {
            abortController.abort()
        }
    }, [token, setCategories])

    const profileDetailsSchema = Yup.object().shape({
        name: Yup.string().required('Category name is required'),
        subtype: Yup.string().required('Type is required'),
    });

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: profileDetailsSchema,
        validateOnMount: true,
        onSubmit: (values,
                   {
                       setStatus,
                       setSubmitting,
                   }) => {
            if(values){
                handleUpdateCategory(values)
            }
        },
    });

    return (
        <>
            <div>
                <div className="d-flex">
                    <button
                        type='button'
                        className='link-primary btn btn_add mt-2 px-0'
                        onClick={handleCreateCategory}
                    >
                        + Add Category
                    </button>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className='fw-bolder'>Name</th>
                            <th className='fw-bolder'>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category, index) => {
                            return <tr className='action_line' key={index}>
                                <td className='action_name'>{category.name}</td>
                                <td className='action_type'>
                                    {category.subtype && (
                                      <span
                                        className={category.subtype === CategorySubtypeEnum.Attendance ? 'badge badge-danger' : 'badge badge-info'}>
                                        {capitalizeName(category.subtype)}
                                      </span>
                                    )}
                                </td>
                                <td className="action_buttons">
                                    <span className="btn_edit ms-3"
                                          onClick={() => handleEditCategory(category)}
                                    >
                                        <i className="fas fa-edit text-primary fs-4"></i>
                                    </span>


                                    {/*Hiding this delete until we can fix api to handle properly*/}

                                    {/*<span className="btn_remove ms-3"*/}
                                    {/*    onClick={() => handleRemoveCategory(category)}*/}
                                    {/*>*/}
                                    {/*    <i className="fas fa-trash text-danger fs-4"></i>*/}
                                    {/*</span>*/}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <SkeModal
                title={formik.values.id === 0 ? 'Create Category' : `Edit: ${category?.name || ''}`}
                show={showCategoryEditModal}
                onClose={handleEditCategoryClose}
                onSubmit={handleOnSubmit}
            >
              <form
                onSubmit={() => console.log('Submit to my command!')}
              >
                  {formik.status && (
                    <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                  )}
                  <div className="row mb-6">
                      <div className="col-lg-6">
                          <label className="col-form-label required fw-bold fs-6">Name</label>
                          <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder=""
                                {...formik.getFieldProps('name')}
                              />
                              {formik.touched.name && formik.errors.name && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.name}</div>
                                </div>
                              )}
                          </div>
                      </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col-lg-6">
                        <label className="col-form-label required fw-bold fs-6">Type</label>
                        <div className="col-lg-12">
                            <select
                              className="form-select form-select-solid form-select-lg fw-bold"
                              {...formik.getFieldProps('subtype')}
                              defaultValue={category?.subtype}
                            >
                                <option value="">Select a Type...</option>
                                {
                                    Object.values(CategorySubtypeEnum).map(
                                      (subtype, i)=>{
                                          return <option
                                            key={i}
                                            value={subtype}>{capitalizeName(subtype)}
                                          </option>;
                                      }
                                    )
                                }
                            </select>
                            {formik.touched.subtype && formik.errors.subtype && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.subtype}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
              </form>
            </SkeModal>

            {showTextPrompt && (
              <SkeDialogTextPrompt
                title={textPromptConfig.title}
                initialValue={textPromptConfig.inputValue}
                onConfirm={(value) => {
                    textPromptConfig.onConfirm(value);
                    setShowTextPrompt(false);
                }}
                onCancel={() => {
                    setShowTextPrompt(false);
                }}
              />
            )}
            {showDeleteDialog && (
              <SkeDialogDelete
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                successMessage='Category deleted'
                message='Are you sure you want to delete this category?'
              />
            )}
        </>
    )
}
