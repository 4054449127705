import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { Pagination } from '../../components/Pagination'
import { getPagination } from '../../CRUD/TeamCRUD'
import { AtRiskEmployeeType } from '../../types/EmployeeType';
import { PaginationLinksModel } from '../../models';
import { Avatar } from '../../components/Avatar';
import { ButtonSortable, useButtonSortable } from '../../../common/components/ButtonSortable';
import { TeamSelect } from "../../../common/components/TeamSelect";
import { SortOrderEnum } from "../../types/SortOrderEnum";
import { SkeSpinner } from '../../../common/components/Spinner';
import clsx from "clsx";

type Props = {
  className: string;
  teamIds?: number[];
  pageLimit?: number;
}

type fields = "currentPoints" | "date" | "category" | "creatorId" | "firstName" | "status";

export function RiskWidget({ className, teamIds, pageLimit= 5 }: Props) {
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
  const [atRiskEmployees, setAtRiskEmployees] = useState<AtRiskEmployeeType[]>([])
  const [paginationMetadata, setPaginationMetadata] = useState<any>()
  const [paginationLinks, setPaginationLinks] = useState<PaginationLinksModel>()
  const {sort, handleSortChange, getSortFieldValue} = useButtonSortable<fields>('currentPoints', SortOrderEnum.Desc);
	const [teamId, setTeamId] = useState<number | undefined>(teamIds?.[0]);
  const [isLoading, setIsLoading] = useState(true)

  const handleAtRiskEmployeesResponse = (response: any) => {
    // TODO: refactor Pagination to receive just the data.meta object
    setPaginationMetadata(response.data);
    setPaginationLinks(response.data.links);
    setAtRiskEmployees(response.data.items);
  }

  const getUrlParams = () => {
    // TODO: need to enable filtering on multiple teams on API
    const teamsParam = teamId ? `&teamId=${teamId}` : '';
    return `&limit=${pageLimit}&sort=${getSortFieldValue()}&isAtRisk=true${teamsParam}`;
  }

  const handlePrevious = () => {
    if (paginationLinks) {
      getPagination(paginationLinks.previous, token)
        .then(handleAtRiskEmployeesResponse)
        .catch(err => {
          console.log(err.response)
        })
    }
  }

  const handleNext = () => {
    if (paginationLinks) {
      getPagination(paginationLinks.next, token)
        .then(handleAtRiskEmployeesResponse)
        .catch(err => {
          console.log(err.response)
        })
    }
  }
  const handlePageIndex = (index: number) => {
    getPagination(`api/employees?page=${index}${getUrlParams()}`, token)
      .then(handleAtRiskEmployeesResponse)
      .catch(err => {
        console.log(err.response)
      })
  }

  const handleSort = (field: fields) => {
		handleSortChange(field);
	}

  useEffect(() => {
    let abortController = new AbortController();

    getPagination(`api/employees?${getUrlParams()}`, token)
      .then(handleAtRiskEmployeesResponse)
      .catch(() => {
        console.log('Bad request')
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {
      abortController.abort();
    }
  }, [teamId, sort, token, pageLimit])

  return (
    <div className={clsx('card overflow-auto', className)}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>At Risk</h3>
        <TeamSelect
          teamId={teamId}
          setTeamId={setTeamId}
          className='my-5'
        />
      </div>
      <div className='card-body pt-2'>
        {atRiskEmployees?.length > 0 &&
          (<table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>
                  <ButtonSortable
                    label="Name"
                    isSorted={sort.field === 'firstName'}
                    sortOrder={sort.order}
                    onSort={() => handleSort('firstName')}
                  />
                </th>
                <th>Team</th>
                <th className='min-w-50px'>
                  <ButtonSortable
                    label="Total Points"
                    isSorted={sort.field === 'currentPoints'}
                    sortOrder={sort.order}
                    onSort={() => handleSort('currentPoints')}
                  />
                </th>
                <th>Last Discipline</th>
              </tr>
            </thead>
            <tbody>
              {
                !isLoading && atRiskEmployees?.map((employee, i) => {
                  return <tr key={i}>
                    <td className='d-flex'>
                      <div className='symbol symbol-45px me-2'>
                        <Avatar
                          className="flex-shrink-0"
                          url={employee.avatarUrl}
                          alt="Employee's avatar" />
                      </div>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href={employee.teams ? employee.teams.length > 0 ? `/team-member/${employee.teams[0].id}/show/${employee.id}` : '#' : '#'} className='text-dark fw-bolder text-hover-primary fs-6'>
                            {employee.firstName} {employee.lastName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      {employee.teams && employee.teams[0].name && <div>{employee.teams[0].name}</div>}
                    </td>
                    <td className='text-start'>
                      <span className='badge badge-light-danger fs-8 fw-bolder'>{employee.currentPoints}</span>
                    </td>
                    <td>
                      {(employee.lastDiscipline) ? employee.lastDiscipline.date : '-'}
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
          )}
        {atRiskEmployees.length === 0 && !isLoading && <div className='fw-normal timeline-content-2 text-muted ps-3'>No Employees At Risk</div>}
        {isLoading && <SkeSpinner className="text-center py-10 fs-5" label="Getting Data..."></SkeSpinner>}
      </div>
      {atRiskEmployees?.length > 0 && <div className='card-footer pt-2 pb-5 border-0'>
        <div className='fs-6 fw-bold text-gray-700'></div>

        <Pagination
          data={paginationMetadata}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          handlePageIndex={handlePageIndex}
        />
      </div>
      }
    </div>
  )
}
