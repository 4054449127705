import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { SkeSpinner } from '../../../../common/components/Spinner';
import { FilterButton } from '../../../components/FilterButton';
import { getPagination } from '../../../CRUD/TeamCRUD'
import { removeAttendance } from '../../../CRUD/AttendanceCRUD'
import { DropdownUserActions } from '../../../components/dropdown/DropdownUserActions'
import { RootState } from '../../../../setup';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../models'
import { AttendanceModal } from './AttendanceModal';
import { Pagination } from '../../../components/Pagination'
import { AttendanceType } from '../../../types/AttendanceType'
import { AttendanceViewModal } from './AttendanceViewModal'
import { ConnectionSortModel } from '../../../models/ConnectionSortModel';
import { ConnectionListFilterModal } from '../ConnectionListFilterModal'
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { Avatar } from "../../../components/Avatar";

type Props = {
    className?: string
}

const AttendancesList: React.FC<Props> = ({ className }) => {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [attendances, setAttendances] = React.useState<any>({ items: [] })
    const [sort, setSort] = React.useState<ConnectionSortModel>({ field: 'date', order: 'DESC' })
    const [selectedAttendance, setSelectedAttendance] = React.useState<any>({})
    const [startDate, setStartDate] = useState<string>(moment().subtract(1, 'years').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(moment().add(1, 'days').format('YYYY-MM-DD'));
    const [teamId, setTeamId] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [supervisorId, setSupervisorId] = useState(0)
    const [pageLimit, setPageLimit] = useState(24)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const viewModalRef = React.useRef<HTMLAnchorElement>(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const applyFilterView = (e: React.MouseEvent, start_date: string, end_date: string, teamIdFilter: number, supervisorIdFilter: number) => {
        setStartDate(start_date);
        setEndDate(end_date);
        setTeamId(teamIdFilter)
        setSupervisorId(supervisorIdFilter)

        // getPagination(`api/connections?limit=${pageLimit}&search=${inputSearch}&fromDate=${startDate}&toDate=${endDate}${status !== '' ? '&status=' + status : ''}`, token)
        getPagination(`api/attendances?limit=${pageLimit}&fromDate=${start_date}&toDate=${end_date}${teamIdFilter !== 0 ? '&teamId=' + teamIdFilter : ''}${supervisorIdFilter !== 0 ? '&creatorId=' + supervisorIdFilter : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
            .then(({ data }) => {
                setAttendances(data);
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    useEffect(() => {
        let abortController = new AbortController();
        setIsLoading(true);
        // getPagination(`api/connections?limit=${pageLimit}&search=${inputSearch}&fromDate=${startDate}&toDate=${endDate}${status !== '' ? '&status=' + status : ''}`, token)
        getPagination(`api/attendances?limit=${pageLimit}&fromDate=${startDate}&toDate=${endDate}${teamId !== 0 ? '&teamId=' + teamId : ''}${supervisorId !== 0 ? '&creatorId=' + supervisorId : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
            .then(({ data }) => {
                let teamNames = []

                for (let i = 0; i < data.items.length; i++) {
                    if (teamNames.indexOf(data.items[i].team.name) === -1) {
                        teamNames.push(data.items[i].team.name);
                    }
                }
                setAttendances(data)
            })
            .catch(err => {
                console.log(err.response)
            })
            .finally(() => {
                setIsLoading(false);
            });

        return () => {
            abortController.abort();
        }
    }, [supervisorId, teamId, token, pageLimit, endDate, startDate, user.roles, sort])

    const handlePrevious = () => {
        getPagination(attendances.links.previous, token)
            .then(({ data }) => {
                setCurrentPageNumber(currentPageNumber - 1)
                let teamNames = []

                for (let i = 0; i < data.items.length; i++) {
                    if (teamNames.indexOf(data.items[i].team.name) === -1) {
                        teamNames.push(data.items[i].team.name);
                    }
                }
                setAttendances(data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }
    const handleNext = () => {
        getPagination(attendances.links.next, token)
            .then(({ data }) => {
                setCurrentPageNumber(currentPageNumber + 1)
                let teamNames = []

                for (let i = 0; i < data.items.length; i++) {
                    if (teamNames.indexOf(data.items[i].team.name) === -1) {
                        teamNames.push(data.items[i].team.name);
                    }
                }
                setAttendances(data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }
    const handlePageIndex = (index: number) => {
        // getPagination(`api/connections?page=${index}&limit=${pageLimit}&search=${inputSearch}&fromDate=${startDate}&toDate=${endDate}${status !== '' ? '&status=' + status : ''}`, token)
        getPagination(`api/attendances?page=${index}&limit=${pageLimit}&fromDate=${startDate}&toDate=${endDate}${teamId !== 0 ? '&teamId=' + teamId : ''}${supervisorId !== 0 ? '&creatorId=' + supervisorId : ''}&sort=${sort.order === 'DESC' ? '-' : ''}${sort.field}`, token)
            .then(({ data }) => {
                setCurrentPageNumber(index)
                let teamNames = []

                for (let i = 0; i < data.items.length; i++) {
                    if (teamNames.indexOf(data.items[i].team.name) === -1) {
                        teamNames.push(data.items[i].team.name);
                    }
                }
                setAttendances(data)
            })
            .catch(err => {
                console.log(err.response)
            })
    }

    const handlePageLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPageLimit(parseInt(e.target.value))
    }

    const handleCancelDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
        setShowDeleteDialog(false);
        if (selectedAttendance) {
            removeAttendance(selectedAttendance.id, token)
              .then(() => {
                  successToast();
                  handlePageIndex(currentPageNumber);
              })
              .catch(err => {
                  console.log(err);
                  errorToast();
              });
        }
    };

    const removeSelectedAttendance = (attendance: AttendanceType) => {
        setShowDeleteDialog(true);
        setSelectedAttendance(attendance);
    }

    return (
        <>
            <div className={`card ${className}`}>
                <div className='card-header border-0 pt-5'>
                    <div className='pt-7 d-flex w-200px align-items-center' id='kt_chat_contacts_header'>
                        <select
                            className="form-select py-2 w-80px text-end"
                            data-control="select2"
                            data-placeholder="Select an option"
                            value={pageLimit}
                            onChange={handlePageLimitChange}
                        >
                            <option value="5">5</option>
                            <option value="12">12</option>
                            <option value="24">24</option>
                            <option value="100">100</option>
                        </select>
                        <span className='ms-2'>rows per page</span>
                    </div>


                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to filter results'
                    >
                        <div className='d-flex align-items-center py-1 me-4'>
                            <div className='me-4'>
                                <FilterButton
                                    toggle='modal'
                                    target='#attendance_filter_modal' />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height: 'calc(100vh - 280px)'}} className='card-body py-3 overflow-auto'>
                    <a
                        ref={viewModalRef}
                        href='#'
                        className='d-none'
                        data-bs-toggle='modal'
                        data-bs-target='#attendance_view_modal'
                    >
                        View
                    </a>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bolder text-muted'>
                                    <th className='min-w-150px'>
                                        <button
                                            className='p-0'
                                            type="button"
                                            onClick={() => {
                                                setSort({
                                                    field: 'firstName',
                                                    order: (sort.field !== 'firstName' || sort.order === 'DESC') ? 'ASC' : 'DESC',
                                                })
                                            }}
                                        >
                                            Member / Supervisor
                                            {sort.field === 'firstName' &&
                                                <span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
                                            }
                                        </button>
                                    </th>
                                    <th className='min-w-140px'>
                                        <button
                                            className='p-0'
                                            type="button"
                                            onClick={() => {
                                                setSort({
                                                    field: 'date',
                                                    order: (sort.field !== 'date' || sort.order === 'ASC') ? 'DESC' : 'ASC',
                                                })
                                            }}
                                        >
                                            Date
                                            {sort.field === 'date' &&
                                                <span className='ms-3'>{sort.order === 'DESC' ? "↓" : "↑"}</span>
                                            }
                                        </button>
                                    </th>
                                    <th className='min-w-120px'>
                                        Team
                                    </th>
                                    <th className='min-w-120px'>
                                        Reason
                                    </th>
                                    <th className='min-w-100px text-end'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && attendances.items.length > 0 &&
                                    (attendances.items as AttendanceType[]).map((attendance, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className='d-flex align-items-center gap-3'>
                                                        <Avatar
                                                            className="flex-shrink-0"
                                                            url={attendance.employee?.avatarUrl}
                                                            alt="employee avatar"
                                                        />
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <Link
                                                                to={`/team-member/${attendance.team?.id}/show/${attendance.employee?.id}`}
                                                                className='text-dark fw-bolder text-hover-primary fs-6'
                                                            >
                                                                {attendance.employee?.firstName} {attendance.employee?.lastName}
                                                            </Link>
                                                            <span className='text-muted fw-bold fs-7'>{attendance.creator?.firstName} {attendance.creator?.lastName}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className='text-muted fw-bold d-block fs-7'>
                                                        {attendance.date}
                                                    </span>
                                                </td>
                                                <td className='text-end'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-muted me-2 fs-7 fw-bold'>{attendance.team?.name}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-start'>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                        <span
                                                                className='me-2 fs-7 fw-bold px-3 rounded'
                                                                style={{ color: attendance.reason.isExcused ? 'green' : 'red' }}
                                                            >
                                                                {attendance.reason.content}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end'>
                                                    <DropdownUserActions
                                                        viewModalId='attendance_view_modal'
                                                        editModalId='attendance-modal'
                                                        handleSelectedIndex={() => { }}
                                                        handleSelectedConnection={() => { setSelectedAttendance(attendance) }}
                                                                                                                removeSelectedConnection={() => removeSelectedAttendance(attendance)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {!attendances.items.length && !isLoading && <tr><td colSpan={7} className='text-center'>No Data</td></tr>}
                            </tbody>
                        </table>
                        {isLoading && <SkeSpinner className="d-flex justify-content-center align-items-center text-center py-10" />}
                        <div className='d-flex flex-stack flex-wrap pb-5'>
                            <div className='fs-6 fw-bold text-gray-700'></div>

                            <Pagination
                                data={attendances}
                                handlePrevious={handlePrevious}
                                handleNext={handleNext}
                                handlePageIndex={handlePageIndex}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <AttendanceViewModal attendance={selectedAttendance} />
            {
                Object.keys(selectedAttendance).length > 0 &&
                <AttendanceModal
                  employee={selectedAttendance?.employee}
                  attendance={selectedAttendance}
                  mode="edit"
                  refresh={() => handlePageIndex(currentPageNumber)} />
            }
            {showDeleteDialog && (
              <SkeDialogDelete
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                successMessage='Attendance deleted'
                message='Are you sure you want to delete this attendance?'
              />
            )}

            <ConnectionListFilterModal
                applyFilter={applyFilterView}
                modalId='attendance_filter_modal'
            />
        </>
    )
}

export default AttendancesList
