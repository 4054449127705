import clsx from 'clsx';
import { difference, values } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SkeBeta } from '../../../common/components/beta';
import { SkeSpinner } from '../../../common/components/Spinner';
import { GridFilterItem, QueryOperators, ResponseWithManyMeta } from '../../../common/interfaces/response-format.model';
import { GetConnectionPayload, getConnections } from '../../../features/connection/ConnectionCRUD';
import {
	ConnectionFilterableFields,
	ConnectionTypeEnumWithCa,
} from '../../../features/connection/interfaces/connection.model';
import { VwConnection, VwConnectionDetails } from '../../../features/connection/interfaces/vw_connection.model';
import { isAiReviewEnabled } from '../../../features/settings/utils/settings.util';
import { TeamSelect } from "../../../common/components/TeamSelect";
import { WorkflowStepToken } from '../../../features/workflow/interfaces/workflow.model';
import { RootState } from '../../../setup';
import { Avatar } from '../../components/Avatar';
import { Pagination } from '../../components/Pagination';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { getPagination } from '../../CRUD/TeamCRUD';
import { UserModel } from '../../models';
import {
	Discipline,
	DisciplineStatusEnum,
	DisciplineStatusForHumansEnum,
} from '../../modules/discipline/models/Discipline.models';
import { DisciplineType } from '../../types/DisciplineType';
import {DisciplineModalReadState, disciplineModalSlice} from '../../../features/discipline/discipline-modal.slice';
import {ModalDisplayMode} from '../../../common/interfaces/modal.model';
import { ButtonSortable, useButtonSortable } from '../../../common/components/ButtonSortable';
import { SortOrderEnum } from "../../types/SortOrderEnum";
import { SkeTooltip } from "../../modules/shared/components/Tooltip";

interface Props {
	tableClasses?: string;
	className: string;
}

type fields = "date" | "category" | "creator" | "-date" | "firstName" | "-firstName" | "status" | "-category" | "-creator" | "-status";

export function RequiredWidget({ className, tableClasses }: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const [disciplineData, setDisciplineData] = useState<any>(null);
	const [teamId, setTeamId] = useState<number>();
	const [isLoadingDisciplines, setIsLoadingDisciplines] = useState<boolean>(true);
	const [isLoadingReviews, setIsLoadingReviews] = useState<boolean>(true);
	const [viewMode, setViewMode] = useState<'action' | 'awareness'>('action');
	const [totalCorrectiveActions, setTotalCorrectiveActions] = useState<number>();
	const [connectionMeta, setConnectionMeta] = useState<ResponseWithManyMeta<VwConnection<VwConnectionDetails>>>();
	const {sort, handleSortChange, getSortFieldValue} = useButtonSortable<fields>('date', SortOrderEnum.Desc);

	let currentDate = moment().tz('US/Central');
	const DURATION_DANGER_THRESHOLD_MINUTES = 60 * 72;
	const DURATION_WARNING_THRESHOLD_MINUTES = 60 * 48;
	const pageLimit = 10;

	const dispatch = useDispatch();

	const loadDisciplines = async (opts?: {pageNumber?: number, link?: string}) => {
		setIsLoadingDisciplines(true);

		const pageParam = (opts?.pageNumber) ? `&page=${opts.pageNumber}` : '';
		const teamFilter = (!!teamId) ? `&teamId=${teamId}` : '';
		const modeParam = `&mode=${viewMode}`;
		const sortParam = `&sort=${getSortFieldValue()}`;
		const url = (opts?.link) ? opts.link : `api/disciplines?limit=${pageLimit}${pageParam}${teamFilter}${modeParam}${sortParam}`;

			await getPagination(url, token)
				.then(response => {
					setDisciplineData(response.data);
					setTotalCorrectiveActions(response.data.meta.totalItems);
				})
			.catch(err => {
				toast.error(`Error loading Corrective Actions`);
			})
			.finally(() => {
				setIsLoadingDisciplines(false);
			});
	};

	const loadConnections = async() => {
		// don't risk confusion if errors trying to get reviews for customers not enabled
		if (!isAiReviewEnabled(user)) {
			return setIsLoadingReviews(false);
		}

		const filters: GridFilterItem<ConnectionFilterableFields>[] = [
			{
				field: 'connection_type_array',
				value: [
					ConnectionTypeEnumWithCa.Review,
				],
				operator: QueryOperators.Equals,
			},
			{
				field: 'status_array',
				value: difference(values(WorkflowStepToken), [WorkflowStepToken.Declined, WorkflowStepToken.Filed]),
				operator: QueryOperators.Contains,
			},
		]
		if (teamId) {
			filters.push({
				field: 'team_ids',
				value: [teamId],
				operator: QueryOperators.Contains,

			})
		}
		const flt: GetConnectionPayload = {
			filters,
			page: 1,
			pageSize: 100,
			sort: [{
				field: 'date',
				dir: 'desc',
				position: 0,
			}],
			detailed: true,
		};

		getConnections(token, flt)
			.then(conns => {
				setConnectionMeta(conns.data.results.meta);
			})
			.catch(err => {
				toast.error('Error loading reviews');
			})
		.finally(() => {
			setIsLoadingReviews(false);
		});
	}

	const handleViewCorrectiveAction = (index: number) => {
		if(disciplineData){
			const payload: DisciplineModalReadState = {
				mode: ModalDisplayMode.Read,
				record: (disciplineData.items[index] as unknown as Discipline),
			}
			dispatch(disciplineModalSlice.actions.show(payload));
		}
	};

	const getHumanReadableDuration = (minutesSinceCreation: number) => {
		const duration = moment.duration(minutesSinceCreation, 'minutes');
		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();

		if (days > 0) {
			return `${days}d ${hours}h`;
		} else if (hours > 0) {
			return `${hours}h ${minutes}m`;
		} else {
			return `${minutes}m`;
		}
	};

	const handleSort = (field: fields) => {
		handleSortChange(field);
	}

	var actionModeMessage = viewMode === 'action' ? 'Showing items requiring action from you' : `Showing items not requiring action from you, but you created or are assigned to review`

	useEffect(() => {
		loadDisciplines();
		loadConnections();
	}, [teamId, token, user, viewMode, sort]);

	return (
		<div className={clsx('card', className)}>
			<div className="card-header border-0 pt-5 d-flex flex-column min-h-auto">
				<div className="gap-3 d-flex align-items-center flex-row justify-content-between flex-wrap">
					<div className="flex-grow-1 justify-content-center justify-content-md-start">
						<h3 className="d-flex flex-column">
							Action Center
						</h3>
						<div className="d-flex flex-column flex-wrap">
							<span className="">{totalCorrectiveActions} Corrective Actions</span>
							{isAiReviewEnabled(user) && (
								<>
									<NavLink
										to="/connections/review"
									>
										{connectionMeta?.pagination.totalItems} Reviews on Connection Grid
										<SkeBeta />
									</NavLink>
								</>
							)}
						</div>
					</div>
					<TeamSelect teamId={teamId} setTeamId={setTeamId}></TeamSelect>

					<ToggleSwitch
						id="ActionModeToggle"
						inactiveValue="awareness"
						activeValue="action"
						activeLabel="Action Mode"
						inactiveLabel="Action Mode"
						isChecked={viewMode === 'action'}
						onChange={setViewMode}
					></ToggleSwitch>
				</div>
				<div className="d-flex justify-content-md-end py-2">
							<span className="fw-light fst-italic">
								<SkeTooltip
									targetElementId="ActionModeToggle"
									message={actionModeMessage} />
							</span>
				</div>
			</div>

			<div>
				{(
					<div className="card-body pt-0 pb-0">
						<div className={clsx(tableClasses, {
							// suppress scrolling with overlay
							'overflow-hidden': isLoadingDisciplines || isLoadingReviews,
							'table-responsive': !isLoadingDisciplines && !isLoadingReviews,
						})}>
							<table className="table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
								<thead className="rounded-3 bg-light">
								<tr className="fw-bolder text-muted">
									<th className="min-w-150px fs-6 text-center">
										<ButtonSortable
											label="Employee"
											isSorted={sort.field === 'firstName'}
											sortOrder={sort.order}
											onSort={() => handleSort('firstName')}/>
									</th>
									<th className="min-w-150px fs-6 text-center">Team</th>
									{user.roles?.includes('SuperAdmin') &&
										<th className="min-w-150px fs-6 text-center">
											<ButtonSortable
												label="Requester"
												isSorted={sort.field === 'creator'}
												sortOrder={sort.order}
												onSort={() => handleSort('creator')}/>
										</th>
									}
									<th className="min-w-140px fs-6 fs-6">
										<ButtonSortable
											label="Date"
											isSorted={sort.field === 'date'}
											sortOrder={sort.order}
											onSort={() => handleSort('date')}/>
									</th>
									<th className="min-w-140px fs-6">
										<ButtonSortable
											label="Category"
											isSorted={sort.field === 'category'}
											sortOrder={sort.order}
											onSort={() => handleSort('category')}/>
									</th>
									<th className="min-w-140px fs-6">Detail</th>
									<th className="min-w-150px fs-6 text-center">Time in Queue</th>
									<th className="min-w-120px fs-6">
										<ButtonSortable
											label="Status"
											isSorted={sort.field === 'status'}
											sortOrder={sort.order}
											onSort={() => handleSort('status')}/>
									</th>
								</tr>
								</thead>
								<tbody className="fs-8 position-relative h-300px">
									{isLoadingDisciplines && <SkeSpinner className="text-center d-block position-absolute" />}
									{
										(disciplineData?.items as DisciplineType[])?.map((discipline, i) => {
											const minSinceCreation = moment.duration(currentDate.diff(moment(discipline.created_at).tz('US/Central'))).asMinutes();
											return <tr
												className="cursor-pointer"
												onClick={() => handleViewCorrectiveAction(i)}
												key={discipline.id}>
												<td>
													<div className="d-flex align-items-center ps-5 gap-3">
														<Avatar
															className="flex-shrink-0"
															url={discipline.employee?.avatarUrl}
															alt="employee avatar"
														/>
														<div className="d-flex justify-content-start flex-column">
															{discipline.employee?.firstName} {discipline.employee?.lastName}
														</div>
													</div>
												</td>
												<td>
													<div className="d-flex align-items-center ps-5">
														<div className="d-flex justify-content-start flex-column">
															{discipline.team?.name}
														</div>
													</div>
												</td>
												{user.roles?.includes('SuperAdmin') &&
													<td>
														<div className="d-flex align-items-center ps-5 gap-3">
															<Avatar
																className="flex-shrink-0"
																url={discipline.creator?.avatarUrl}
																alt="creator avatar"
															/>
															<div className="d-flex justify-content-start flex-column">
																{discipline.creator?.firstName} {discipline.creator?.lastName}
															</div>
														</div>
													</td>
												}
												<td>
													{discipline.date}
												</td>
												<td>
													{discipline.category.name}
												</td>
												<td>
													<div
														className="d-block"
														style={{color: discipline.step.stepColor}}>
														{discipline.step.name}
													</div>
												</td>
												<td>
												<span
													className={clsx('btn d-block',
														{
															'text-danger': minSinceCreation >= DURATION_DANGER_THRESHOLD_MINUTES && discipline.status !== DisciplineStatusEnum.Draft,
															'text-warning': minSinceCreation >= DURATION_WARNING_THRESHOLD_MINUTES && discipline.status !== DisciplineStatusEnum.Draft,
															'text-success': minSinceCreation < DURATION_WARNING_THRESHOLD_MINUTES && discipline.status !== DisciplineStatusEnum.Draft,
															'text-primary': discipline.status === DisciplineStatusEnum.Draft,
														})}
												>
													{![
															DisciplineStatusEnum.DisciplineDeclined,
															DisciplineStatusEnum.DocumentationFiled,
														].includes(discipline.status) &&
														<>
															{getHumanReadableDuration(minSinceCreation)}
														</>
													}
												</span>
												</td>
												<td className="text-end">
													<div className="d-flex flex-column w-100 me-2">
														<div className="d-flex flex-stack">
													<span
														className={clsx('me-2 badge-light px-3 badge',
															{
																'badge-light': [
																	DisciplineStatusEnum.Draft,
																	DisciplineStatusEnum.IssuedToEmployee,
																	DisciplineStatusEnum.SubmittedToHr,
																	DisciplineStatusEnum.ReceivedByHr,
																	DisciplineStatusEnum.DocumentationFiled,
																	DisciplineStatusEnum.DisciplineDeclined,
																].includes((discipline as unknown as Discipline).status),
																'badge-light-warning text-dark': [
																	DisciplineStatusEnum.RequestedChange,
																	DisciplineStatusEnum.RequestedApproval,
																].includes((discipline as unknown as Discipline).status),
																'badge-light-primary': [DisciplineStatusEnum.Approved].includes((discipline as unknown as Discipline).status),
															})}>
														{DisciplineStatusForHumansEnum[discipline.status]}
													</span>
														</div>
													</div>
												</td>
											</tr>;
										})
									}
								</tbody>
							</table>
							{!totalCorrectiveActions && !isLoadingDisciplines &&
								<h3 className="fw-bold text-center mt-20">No Records found</h3>
							}
						</div>
					</div>
				)}
			</div>
			<div className="card-footer py-2 border-0">
				{disciplineData ? disciplineData.items.length > 0 &&
					<Pagination
						data={disciplineData}
						handlePrevious={() => loadDisciplines({link: disciplineData.links.previous})}
						handleNext={() => loadDisciplines({link: disciplineData.links.next})}
						handlePageIndex={(pageNumber => loadDisciplines({pageNumber}))}
					/> : null}
			</div>
		</div>
	);
}
