import { useState, useMemo } from 'react';

type SearchFilter<T> = (item: T, query: string) => boolean;

function useSearch<T>(
  collection: T[] | undefined,
  filterFunction: SearchFilter<T>
) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredList = useMemo(() => {
    if (!collection) {
      return [];
    }
    if (!searchQuery.trim()) {
      return collection;
    }
    return collection.filter((item) => filterFunction(item, searchQuery));
  }, [collection, searchQuery, filterFunction]);

  return { searchQuery, setSearchQuery, filteredList };
}

export default useSearch;