import React from 'react'
import {teamSlice} from '../../../../features/team/team.api'
import {AsideMenuTeamItem} from './AsideMenuTeamItem'
import {Team} from '../../../../app/modules/team/models/Team.model'
import useSearch from '../../../../common/hooks/useSearch'
import './AsideTeamList.scss'

export function AsideTeamList() {
	const {data: teams} = teamSlice.useGetAllTeamsQuery()
	const sortedTeams = teams?.slice().sort((a, b) => a.name.localeCompare(b.name))

	const {searchQuery, setSearchQuery, filteredList} = useSearch<Team>(sortedTeams, (t, query) =>
		t.name.toLowerCase().includes(query.toLowerCase())
	)

	return (
		<>
			<input
				className="mx-9 mb-3 p-3 bg-transparent border-1 rounded"
				type='text'
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				placeholder='Search teams...'
			/>
			<div className='team_list_wrapper mb-3'>
				{filteredList?.map((team) => {
					return (
						<AsideMenuTeamItem
							to={`/teams/show/${team.id}`}
							title={team.name}
							key={team.id}
							hasBullet={false}
							avatarUrl={team.avatarUrl}
						/>
					)
				})}
			</div>
		</>
	)
}
