import clsx from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { TeamModel } from '../../features/team/interfaces/team.model';
import { teamSlice } from '../../features/team/team.api';

interface TeamSelectProps {
	teamId: number | undefined;
	setTeamId: (id: number | undefined) => void;
	className?: string;
}

export function TeamSelect({
														 teamId,
														 setTeamId,
														 className,
													 }: TeamSelectProps) {
	const {
		data: teams,
		isSuccess: teamsSuccess,
	} = teamSlice.useGetAllTeamsQuery();
	const [sortedTeams, setSortedTeams] = useState<TeamModel[]>([]);

	useEffect(() => {
		if (teamsSuccess && teams?.length) {
			setSortedTeams(teams.slice().sort((a, b) => a.name.localeCompare(b.name)));
		}
	}, [teams]);

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		setTeamId(value === '' ? undefined : +value);
	};

	if (!teamsSuccess || teams?.length < 2) {
		return null;
	}

	return (
		<select
			id="team-select"
			value={teamId === undefined ? '' : teamId}
			onChange={handleChange}
			className={clsx('form-select form-select-solid w-auto ms-auto mb-5', className)}
			disabled={!teamsSuccess}
		>
			<option value="">All teams</option>
			{teamsSuccess &&
				sortedTeams.map((team: TeamModel) => (
					<option
						key={team.id}
						value={team.id}
					>
						{team.name}
					</option>
				))}
		</select>
	);
}
