import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive } from '../../../helpers';
import { useLayout } from '../../core';

interface Props {
	to: string;
	title: string;
	avatarUrl: string;
	icon?: string;
	fontIcon?: string;
	hasBullet?: boolean;
	children?: ReactNode[];
}

export function AsideMenuTeamItem({
																		children,
																		to,
																		title,
																		fontIcon,
																		hasBullet,
																	}: Props) {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);
	const { config } = useLayout();
	const { aside } = config;

	return (
		<div className="menu-item">
			<Link
				className={clsx('menu-link without-sub', { active: isActive })}
				to={to}>
				<span className="team-bullet flex-shrink-0"></span>
				{hasBullet && (
					<span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
				)}
				{fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
				<span className={'menu-title ' + (to === '#' && 'text-muted')}>{title}</span>
			</Link>
			{children}
		</div>
	);
}
;
