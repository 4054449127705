import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { shallowEqual, useSelector } from 'react-redux'
import { teamSlice } from '../../../../features/team/team.api';
import { PageTitle } from '../../../../_library/layout/core'
import { toAbsoluteUrl } from '../../../../_library/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getSupervisors, } from '../../../CRUD/TeamCRUD'
import { RootState } from '../../../../setup';
import { UserModel } from '../../../models';
import {toast} from 'react-toastify'

const multiSelectCustomStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#eef3f7',
    borderColor: '#eef3f7'
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    color: '#5E6278'
  })
}

interface IProfileDetails {
  name: string
  location: string
  department: string
  avatar: any
  status: string
  shift: string
  assignSupervisor: string
}
const initialValues: IProfileDetails = {
  name: '',
  location: '',
  department: '',
  avatar: '',
  status: 'Active',
  shift: '',
  assignSupervisor: ''
}

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Team name is required'),
  location: Yup.string().required('Location is required'),
  department: Yup.string().required('Department ID is required'),
  shift: Yup.string().required('Shift is required')
})

interface Option {
  value: string
  label: string
}

const CreateTeam: React.FC = () => {
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate();
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [loading, setLoading] = useState(false)
  const [avatarFile, setAvatarFile] = useState<any>()
  const [supervisors, setSupervisors] = useState<UserModel[]>([])
  const [selectedOption, setSelectedOption] = useState<Option[]>([])
  const [createTeam] = teamSlice.useCreateTeamMutation();
  const [assignSupervisor] = teamSlice.useAssignSupervisorMutation();
  const [uploadAvatar] = teamSlice.useUploadAvatarMutation();

  const handleSelectChange = (option: readonly Option[]) => {
    setSelectedOption(option.map(opt => opt))
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      let file = e.target.files[0];
      setAvatarFile(file)
      reader.onloadend = () => {
        if (reader && reader.result) {
          setData({ ...data, avatar: reader.result })
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const removeAvatar = () => {
    setData({ ...data, avatar: '' })
  }

  React.useEffect(() => {
    if (user.company) {
      getSupervisors(user.company.id, token)
        .then(({ data }) => {
          setSupervisors(data.items)
        })
        .catch(err => {
          console.log(err.response)
        })
    }
  }, [token, user.company])

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    validateOnMount: true, 
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      createTeam({
        name: values.name,
        location: values.location,
        department: values.department,
        shift: values.shift,
      }).then(res => {
        if ('error' in res) {
          setLoading(false);
          return console.error("Error: ", res.error );
        } else {
          const asyncCalls = [];
          // @ts-ignore: TODO: need to figure out how to tell it that data is part of response
          const team_id = res.data.id;
          toast.success(`Created team ${res.data.name}`)
          selectedOption.map(async (sup) => {
            const supervisor = {team_id, email: sup.value};
            asyncCalls.push(assignSupervisor(supervisor));
          });
          if (avatarFile) {
            asyncCalls.push(uploadAvatar({
              team_id,
              avatarFile,
            }));
          }

          if (!asyncCalls.length) {
            setLoading(false);
            navigate(`/teams/show/${team_id}`)
          }

          Promise.all(asyncCalls)
            .then(res => {
              setLoading(false);
              console.log('finished post-processing team creation', res);
              navigate(`/teams/show/${team_id}`)
            })
            .catch(err => {
              setLoading(false);
              console.error('Errored post-processing after team creation', err);
            });
        }
      });
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>New Team</PageTitle>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_create_team_form'
        onSubmit={formik.handleSubmit}
      >
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        <div className="d-flex flex-column flex-lg-row">
          {/*begin::Sidebar*/}
          <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
              {/*begin::Card body*/}
              <div className="card-body">
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Team Avatar</label>
                  <div className='col-lg-12 text-center'>
                    <div className="d-flex align-items-center justify-content-center rounded bg-white bg-body shadow w-200px h-200px m-auto">
                      <div
                        className={`image-input image-input-outline w-120px h-120px ${!data.avatar || data.avatar === '' ? 'image-input-empty' : ''}`}
                        data-kt-image-input='true'
                        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/icons/duotune/general/create-team-icon.svg')})` }}
                      >
                        <div
                          className='image-input-wrapper no-shadow shadow-none'
                          style={{ backgroundImage: `url(${toAbsoluteUrl(data.avatar)})` }}
                        ></div>
                        <label className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                          <i className="bi bi-pencil-fill fs-7"></i>
                          <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                          <input type="hidden" name="avatar_remove" />
                        </label>
                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="" data-bs-original-title="Remove avatar" onClick={removeAvatar}>
                          <i className="bi bi-x fs-2"></i>
                        </span>
                      </div>
                    </div>
                    <div className="form-text">Set the product thumbnail image. Only
                      *.png, *.jpg and *.jpeg image files
                      are accepted</div>
                  </div>
                </div>
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card*/}
          </div>
          {/*end::Sidebar*/}
          {/*begin::Content*/}
          <div className="flex-lg-row-fluid ms-lg-15">
            {/*begin:::Tabs*/}
            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
              {/*begin:::Tab item*/}
              <li className="nav-item">
                <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_user_view_overview_tab">General</a>
              </li>
              {/*end:::Tab item*/}
            </ul>
            {/*end:::Tabs*/}
            {/*begin:::Tab content*/}
            <div className="tab-content" id="myTabContent">
              {/*begin:::Tab pane*/}
              <div className="tab-pane fade active show" id="kt_user_view_overview_tab" role="tabpanel">
                {/*begin::Card*/}
                <div className="card card-flush mb-6 mb-xl-9">
                  {/*begin::Card header*/}
                  <div className="card-header mt-6">
                    {/*begin::Card title*/}
                    <div className="card-title flex-column">
                      <h2 className="mb-1">General</h2>
                    </div>
                    {/*end::Card title*/}
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Card body*/}
                  <div className="card-body p-9 pt-4 mb-lg-10">
                    <div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Team Name</label>

                      <div className='col-lg-12'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder=''
                          {...formik.getFieldProps('name')}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.name}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>Assign Supervisor(s)</label>

                      <div className='col-lg-12'>
                        <Select
                          styles={multiSelectCustomStyles}
                          isMulti={true}
                          value={selectedOption}
                          onChange={handleSelectChange}
                          options={supervisors.map(supervisor => { return { value: supervisor.email, label: supervisor.firstName + ' ' + supervisor.lastName } })}
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label required fw-bold fs-6'>Shift</label>
                      <div className='col-lg-12'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder=''
                          {...formik.getFieldProps('shift')}
                        />
                        {formik.touched.shift && formik.errors.shift && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.shift}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card*/}
                {/*begin::Tasks*/}
                <div className="flex-column d-flex flex-column flex-lg-row row">
                  <div className="col-lg-6">
                    <div className="card card-flush mb-2 mb-xl-9 me-lg-5">
                      {/*begin::Card header*/}
                      <div className="card-header mt-0">
                        {/*begin::Card title*/}
                        <div className="card-title flex-column">
                          <h2 className="mb-1">Location</h2>
                        </div>
                        {/*end::Card title*/}
                      </div>
                      {/*end::Card header*/}
                      {/*begin::Card body*/}
                      <div className="card-body d-flex flex-column pt-0">
                        {/*begin::Item*/}
                        <label className='col-form-label required fw-bold fs-6'>Location</label>
                        <div className='col-lg-12'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps('location')}
                          />
                          {formik.touched.location && formik.errors.location && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.location}</div>
                            </div>
                          )}
                        </div>
                        {/* <button type="button" className="btn btn-light-primary btn-sm mt-5">
                          Create Location
                        </button> */}
                        {/*end::Item*/}
                      </div>
                      {/*end::Card body*/}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="card card-flush mb-6 mb-xl-9 ms-lg-5">
                      {/*begin::Card header*/}
                      <div className="card-header mt-0">
                        {/*begin::Card title*/}
                        <div className="card-title flex-column">
                          <h2 className="mb-1">Department</h2>
                        </div>
                        {/*end::Card title*/}
                      </div>
                      {/*end::Card header*/}
                      {/*begin::Card body*/}
                      <div className="card-body d-flex flex-column pt-0">
                        {/*begin::Item*/}
                        <label className='col-form-label required fw-bold fs-6'>Department</label>
                        <div className='col-lg-12'>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                            placeholder=''
                            {...formik.getFieldProps('department')}
                          />
                          {formik.touched.department && formik.errors.department && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.department}</div>
                            </div>
                          )}
                        </div>
                        {/* <button type="button" className="btn btn-light-primary btn-sm mt-5">
                          Create Department
                        </button> */}
                        {/*end::Item*/}
                      </div>
                      {/*end::Card body*/}
                    </div>
                  </div>

                </div>
                <div className="col-6 ms-auto text-center text-lg-end text-end">
                  <button type="button" className="btn btn-light btn-active-primary btn-sm mt-5">
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary btn-sm mt-5 ms-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Create Team</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/*end::Tasks*/}
              </div>
              {/*end:::Tab pane*/}
            </div>
            {/*end:::Tab content*/}
          </div>
          {/*end::Content*/}
        </div>
      </form>
    </>
  )
}

export { CreateTeam }
