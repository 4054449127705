import { skipToken } from '@reduxjs/toolkit/query';
import { compact, concat, get } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { sortDateDescending } from '../../../_library/helpers/sortDate';
import { PageLink, PageTitle } from '../../../_library/layout/core';
import { SkeBeta } from '../../../common/components/beta';
import { ModalDisplayMode } from '../../../common/interfaces/modal.model';
import { QueryOperators } from '../../../common/interfaces/response-format.model';
import { AiReviewModalState, AiReviewModalStateUnion } from '../../../features/ai/review/ai.review-modal.slice';
import { AIReviewModal } from '../../../features/ai/review/modal/AIReviewModal';
import { GetConnectionPayload, getConnections } from '../../../features/connection/ConnectionCRUD';
import { ConnectionTypeEnumWithCa } from '../../../features/connection/interfaces/connection.model';
import { VwConnection, VwConnectionDetails } from '../../../features/connection/interfaces/vw_connection.model';
import { DisciplineModalCreateState, disciplineModalSlice } from '../../../features/discipline/discipline-modal.slice';
import {
	Recognition,
	RecognitionsGetAllApiRequestModel,
} from '../../../features/recognition/interfaces/recognition.model';
import {
	RecognitionModalCreateState,
	recognitionModalSlice,
} from '../../../features/recognition/recognition-modal.slice';
import { recognitionSlice } from '../../../features/recognition/recognition.api';
import { isAiReviewEnabled } from '../../../features/settings/utils/settings.util';
import { teamSlice } from '../../../features/team/team.api';
import { RootState } from '../../../setup';
import { Icon } from '../../components/icons/Icon';
import { Download, OpenAI } from '../../components/icons/IconList';
import { getEmployee, getEmployeeAttendances, getEmployeeCoaching, getEmployeeDisciplines } from '../../CRUD/TeamCRUD';
import { UserModel } from '../../models';
import { AttendanceGetParamsModel } from '../../modules/attendance/models/AttendanceGetParamsModel';
import { SHORT_DATE } from '../../modules/date/DateFormat.const';
import { Employee } from '../../modules/employee/models/Employee.model';
import { AttendanceType } from '../../types/AttendanceType';
import { CoachingType } from '../../types/CoachingType';
import { ConnectionType, ConnectionTypeEnum, ConnectionUnion } from '../../types/ConnectionType';
import { DisciplineType } from '../../types/DisciplineType';
import { EmployeeType, TeamType } from '../../types/EmployeeType';
import { PerformanceReportConfigType } from '../../types/PerformanceReportConfigType';
import { SortDateEnum } from '../../types/SortDateEnum';
import { AttendanceModal } from '../connections/attendance/AttendanceModal';
import { AddCoachingModal } from '../connections/coaching/AddCoachingModal';
import { EmployeePerformanceCard } from '../dashboard/_modals/employee/EmployeePerformanceCard';
import { EmployeePerformanceCardConfig } from '../dashboard/_modals/employee/EmployeePerformanceCardConfig';
import { ChangeTeamModal } from './components/ChangeTeamModal';
import { EmployeeCardInformation } from './components/EmployeeCardInformation';
import { TeamCard } from './components/TeamCard';
import './TeamMemberView.scss';

type RouteParams = {
	teamId: string;
	id: string;
}

const parseConnectionsForCalendars = (item: (ConnectionUnion|Recognition|VwConnection<VwConnectionDetails>)) => {
	let type: ConnectionType | undefined = get(item, 'connectionType', get(item, 'connection_type', undefined));
	if (type === 'ATTENDANCE' && (item as AttendanceType).reason.isExcused) {
		type = ConnectionTypeEnum.Excused;
	}
	return {
		type,
		// needed for monthly calendar
		yearMonth: item.date?.slice(0, 7),
		date: item.date,
		// both needed for yearly calendar
		startDate: moment(item.date).toDate(),
		endDate: moment(item.date).toDate(),
	};
};

export function TeamMemberView() {
	const {
		teamId,
		id,
	} = useParams<RouteParams>();
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const [employee, setEmployee] = useState<EmployeeType>();
	const [attendances, setAttendances] = useState<AttendanceType[]>([]);
	const [coachings, setCoachings] = useState<CoachingType[]>([]);
	const [disciplines, setDisciplines] = useState<DisciplineType[]>([]);
	const [coachingSources, setCoachingSources] = useState<Array<any>>([]);
	const [recognitionSources, setRecognitionSources] = useState<Array<any>>([]);
	const [attendanceSources, setAttendanceSources] = useState<Array<any>>([]);
	const [disciplineSources, setDisciplineSources] = useState<Array<any>>([]);
	const [genericConnectionSources, setGenericConnectionSources] = useState<Array<any>>([]);
	const showActionModalRef = useRef<HTMLButtonElement>(null);
	const [combinedConnections, setCombinedConnections] = useState<(AttendanceType|CoachingType|DisciplineType|Recognition|VwConnection<VwConnectionDetails>)[]>([]);
	const [showAIReviewModal, setShowAIReviewModal] = useState<boolean>(false);
	const [isLoadingConnections, setIsLoadingConnections] = useState<boolean>(true);

	// for display on the employee profile
	const [connectionStartDate, setConnectionStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
	// grabbing 1 month out for Corrective Actions scheduled in the future
	const [connectionEndDate, setConnectionEndDate] = useState<Date>(moment().add(1, 'month').toDate());

	// for display on the instant performance report
	const [reportStartDate, setReportStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
	const [reportEndDate, setReportEndDate] = useState<Date>(moment().add(1, 'day').toDate());
	const [reportAttendances, setReportAttendances] = useState<AttendanceType[]>([]);
	const [reportCoachings, setReportCoachings] = useState<CoachingType[]>([]);
	const [reportRecognitions, setReportRecognitions] = useState<Recognition[]>([]);
	const [reportDisciplines, setReportDisciplines] = useState<DisciplineType[]>([]);
	const [performanceReportConfig, setPerformanceReportConfig] = useState<PerformanceReportConfigType>({
		attendance: true,
		excused: false,
		coaching: true,
		combineConnections: false,
		discipline: true,
		recognition: true,
		startDate: reportStartDate,
		endDate: reportEndDate,
	});
	const { data: teams } = teamSlice.useGetAllTeamsQuery();
	const { data: teamInfo } = teamSlice.useGetOneTeamQuery(teamId ? {team_id: +teamId} : skipToken);
	const connectionsFilter: RecognitionsGetAllApiRequestModel = {
		// `id` has to be here as it's non-optional on the route
		employeeId: +id!,
		fromDate: moment(connectionStartDate).format(SHORT_DATE),
		toDate: moment(connectionEndDate).format(SHORT_DATE),
		limit: 100,
	}
	const { data: recognitionsResponse, fulfilledTimeStamp: rcgTimestamp, refetch: refreshRecognitions } = recognitionSlice.useGetRecognitionsQuery(connectionsFilter, {refetchOnMountOrArgChange: true});
	const [genericConnections, setGenericConnections] = useState<VwConnection<VwConnectionDetails>[]>([]);
	const [getReportRecognitionsQuery] = recognitionSlice.useLazyGetRecognitionsQuery();
	const aiReviewModalState = useSelector<RootState>(state => state.aiReviewModal) as AiReviewModalStateUnion & Pick<AiReviewModalState, 'showModal' | 'mutationRan'>;

	const dispatch = useDispatch();
	const handleNewRecognitionClick = () => {
		const payload: RecognitionModalCreateState = {
			mode: ModalDisplayMode.Create,
			employee: (employee as unknown as Employee),
		}
		dispatch(recognitionModalSlice.actions.show(payload));
	};

	const handleNewDisciplineClick = () => {
		const payload: DisciplineModalCreateState = {
			mode: ModalDisplayMode.Create,
			employee: (employee as unknown as Employee),
		}
		dispatch(disciplineModalSlice.actions.show(payload));
	};

	useEffect(() => {
		const rcg = recognitionsResponse?.items || [];
		setReportRecognitions(rcg);
		setRecognitionSources(rcg.map(parseConnectionsForCalendars));
	}, [recognitionsResponse, rcgTimestamp]);

	const refreshEmployeeData = () => {
		if (!id) {
			return console.error('No employee id set');
		}
		getEmployee(+id, token)
			.then(({ data }) => {
				setEmployee(data);
			})
			.catch(err => {
				console.log(err.response);
			});
	};

	useEffect(() => {
		if (!!token && !!id) {
			refreshEmployeeData();
			loadConnectionsForHistory();
		}
	}, [id, token, aiReviewModalState]);

	const loadConnectionsForHistory = async () => {
		setIsLoadingConnections(true);
		const attReq = getAttendances(connectionStartDate, connectionEndDate);
		const coachReq = getCoachings(connectionStartDate, connectionEndDate);
		const discReq = getDisciplines(moment(connectionStartDate).format(SHORT_DATE), moment(connectionEndDate).format(SHORT_DATE));
		const flt: GetConnectionPayload = {
			filters: [
				{
					field: 'employee_ids',
					value: [id],
					operator: QueryOperators.Equals,
				},
				{
					field: 'after_date',
					value: moment(connectionStartDate).format(SHORT_DATE),
					operator: QueryOperators.GreaterThan,
				},
				{
					field: 'before_date',
					value: moment(connectionEndDate).format(SHORT_DATE),
					operator: QueryOperators.LessThan,
				},
				{
					field: 'connection_type_array',
					value: [ConnectionTypeEnumWithCa.Review],
					operator: QueryOperators.Equals,
				},
			],
			page: 1,
			pageSize: 100,
			sort: [{
				field: 'date',
				dir: 'desc',
				position: 0,
			}],
			detailed: true,
		};
		const genericConnectionReq = getConnections(token, flt);

		await Promise.all([
			attReq,
			coachReq,
			discReq,
			genericConnectionReq,
			refreshRecognitions(),
		]).then(([att, coach, disc, gen]) => {
			setAttendanceSources(att.map(parseConnectionsForCalendars));
			setAttendances(att);

			setCoachingSources(coach.map(parseConnectionsForCalendars));
			setCoachings(coach);

			setDisciplineSources(disc.map(parseConnectionsForCalendars));
			setDisciplines(disc);

			setGenericConnectionSources(gen.data.results.items.map(parseConnectionsForCalendars));
			setGenericConnections(gen.data.results.items);
			setIsLoadingConnections(false);
		});
	};

	const getAttendances = async (fromDate: Date, toDate: Date): Promise<AttendanceType[]> => {
		const opts: AttendanceGetParamsModel = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			includeFuture: 'true',
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			const { data } = await getEmployeeAttendances(+id, token, opts);
			return data.items;
		} catch (err: any) {
			console.log(err.response);
			return [];
		}

	};

	const getCoachings = async (fromDate: Date, toDate: Date): Promise<CoachingType[]> => {
		const opts = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			const { data } = await getEmployeeCoaching(parseInt(id), token, opts);
			return data.items;
		} catch (err: any) {
			console.log(err.response);
			return [];
		}

	};

	const getDisciplines = async (fromDate: string, toDate: string): Promise<DisciplineType[]> => {
		const opts = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Desc,
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			const { data } = await getEmployeeDisciplines(parseInt(id), token, opts);
			return data.items;
		} catch (err: any) {
			console.log(err.response);
			return [];
		}

	};

	const refreshData = async () => {
		refreshEmployeeData();
		await loadConnectionsForHistory();
	};

	useEffect(() => {
		const newConnections: (AttendanceType|CoachingType|DisciplineType|Recognition|VwConnection<VwConnectionDetails>)[] = compact(
			concat<(AttendanceType|CoachingType|DisciplineType|Recognition|undefined|VwConnection<VwConnectionDetails>)>(
				attendances,
				coachings,
				disciplines,
				recognitionsResponse?.items,
				genericConnections,
			)
		);
		newConnections.sort(sortDateDescending);
		setCombinedConnections(newConnections);
	}, [attendances, coachings, disciplines, recognitionSources, genericConnections]);

	const getTeamLink = (): PageLink[] => {
		if (employee && employee.teams?.[0]) {
			return [
				{
					isActive: true,
					path: `/teams/show/${employee.teams[0].id}`,
					title: employee.teams[0].name,

				},
			];
		}
		return [];
	};

	const handleGenerateReport = async (config: PerformanceReportConfigType) => {
		Promise.all([
						 getAttendances(moment(config.startDate).toDate(), moment(config.endDate).toDate()),
						 getCoachings(moment(config.startDate).toDate(), moment(config.endDate).toDate()),
						 getReportRecognitionsQuery({
							 employeeId: +id!,
							 fromDate: moment(config.startDate).format(SHORT_DATE),
							 toDate: moment(config.endDate).format(SHORT_DATE),
							 sort: SortDateEnum.Desc,
							 limit: 100}).unwrap(),
						 getDisciplines(moment(config.startDate).format(SHORT_DATE), moment(config.endDate).format(SHORT_DATE)),
					 ])
					 .then(val => {
						 const [att, coach, recog, disc] = val;

						 setReportAttendances(att);
						 setReportCoachings(coach);
						 setReportRecognitions(recog?.items);
						 setReportDisciplines(disc);

						 if(showActionModalRef.current) {
							 showActionModalRef.current.click();
						 }
					 })
					 .catch(err => {
						 console.log('Error loading connections for Instant Performance Report', err);
					 });
	}

	//AI review modal
	const handleShowAIReviewModal = ()=> {
		setShowAIReviewModal(true)
	}
	const handleAIReviewModalClose = (action: boolean) => {
		setShowAIReviewModal(action);
	}
	const handleAIReviewModalSubmit = () => {
		setShowAIReviewModal(false);
	}

	return (
		<>
			<PageTitle breadcrumbs={getTeamLink()}>Team Member</PageTitle>
			<div className="d-flex flex-column flex-lg-row">
				{employee?.id && <TeamCard employee={employee}
																	 refreshEmployeeData={refreshEmployeeData} />}
				<div className="flex-lg-row-fluid ms-lg-15">
						<div className="d-flex mb-8 flex-row flex-wrap">
							{!employee?.isTerminated && (
								<>
									<button
										type="button"
										className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
										onClick={handleNewRecognitionClick}
									>
										New Recognition
									</button>

									<button
										type="button"
										className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
										data-bs-toggle="modal"
										data-bs-target="#add_coaching_modal"
									>
										New Coaching
									</button>

									<button
										type="button"
										className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
										data-bs-toggle="modal"
										data-bs-target='#new-attendance-from-team-member-view'
									>
										New Attendance
									</button>

									<button
										type="button"
										className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
										onClick={() => handleNewDisciplineClick()}
									>
										New Corrective Action
									</button>

									{isAiReviewEnabled(user) && (
										<>
											<button
												type="button"
												className="btn btn-outline btn-outline-primary btn-active-light-primary px-5 m-1"
												onClick={() => handleShowAIReviewModal()}
											>
												<Icon
													type="svg"
													icon={OpenAI}
													classes="me-1"
													size="sm"></Icon>
												<span>AI Performance Review<SkeBeta></SkeBeta></span>
											</button>
										</>
									)}
								</>
							)}
							<button
								type="button"
								className="btn btn-light-primary ms-auto"
								data-bs-toggle="modal"
								data-bs-target="#employee_performance_card_config"
							>
								<Icon
									type="svg"
									icon={Download}
									classes="me-1"
									size="sm"></Icon>
								<span>Instant Performance Report</span>
							</button>
						</div>
					{/*end:::Tabs*/}
					{/*begin:::Tab content*/}

					<EmployeeCardInformation
						attendanceSources={attendanceSources}
						coachingSources={coachingSources}
						recognitionSources={recognitionSources}
						disciplineSources={disciplineSources}
						genericSources={genericConnectionSources}
						combinedConnections={combinedConnections}
						isLoadingConnections={isLoadingConnections}
						employee={employee!}
						refresh={refreshData}
						showAiReview={!!get(employee, 'company.settings.values', '')}
					/>
					{/*end:::Tab content*/}
				</div>
			</div>
			{employee?.id &&
				<>
					<EmployeePerformanceCard
						employee={employee}
						attendances={reportAttendances}
						coachings={reportCoachings}
						recognitions={reportRecognitions || []}
						disciplines={reportDisciplines}
						config={performanceReportConfig}
					/>
					<EmployeePerformanceCardConfig
						config={performanceReportConfig}
						setConfig={setPerformanceReportConfig}
						onGenerateReport={handleGenerateReport}
					/>

					<button
						className="d-none"
						type="button"
						data-bs-toggle="modal"
						data-bs-target="#employee_performance_card"
						ref={showActionModalRef}
					></button>
					<AddCoachingModal
						employee={employee}
						teamInfo={(teamInfo as unknown as TeamType)}
						refresh={refreshData}
					/>
					{employee && <AttendanceModal
						employee={employee}
						modalId='new-attendance-from-team-member-view'
						mode="new"
						refresh={refreshData}
					/>}
					<ChangeTeamModal
						employee={employee}
						teams={(teams as unknown as TeamType[])}
						refresh={refreshEmployeeData}
					/>
					<AIReviewModal
						show={showAIReviewModal}
						employee={(employee as unknown as Employee)}
						token={token}
						onClose={handleAIReviewModalClose}
						onSubmit={handleAIReviewModalSubmit}
					/>
				</>
			}
		</>
	);
}
