import { ColDef, FilterModel, GridOptions, IGetRowsParams, SortModelItem, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { toPairs } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../_library/helpers';
import { Icon } from '../../../app/components/icons/Icon';
import { Binoculars } from '../../../app/components/icons/IconList';
import { UserModel } from '../../../app/models';
import { DateFormatUsingDateFns } from '../../../app/modules/date/DateFormat.const';
import { parseDateObjectToNewFormat } from '../../../app/modules/date/utils/DateHelpers';
import { UserRoleEnum } from '../../../app/modules/user/models/User.model';
import { SkeSpinner } from '../../../common/components/Spinner';
import { ModalDisplayMode } from '../../../common/interfaces/modal.model';
import { GridFilterItem } from '../../../common/interfaces/response-format.model';
import {
	AgGridFilterModelTypeSupported,
	AgGridFilterTypeToSecchiOperators,
	convertGridSortToSecchiSort,
	QueryOperators,
} from '../../../common/utilities/ag-grid.utility';
import { RootState } from '../../../setup';
import { aiReviewModalSlice, AiReviewModalState, AiReviewModalStateUnion } from '../../ai/review/ai.review-modal.slice';
import {
	attendanceModalSlice,
	AttendanceModalState,
	AttendanceModalStateUnion,
} from '../../attendance/attendance-modal.slice';
import { coachingModalSlice, CoachingModalState, CoachingModalStateUnion } from '../../coaching/coaching-modal.slice';
import {
	disciplineModalSlice,
	DisciplineModalState,
	DisciplineModalStateUnion,
} from '../../discipline/discipline-modal.slice';
import { Logger } from '../../log/components/Logger';
import { LogType, SkeDomain } from '../../log/ske-exception';
import {
	recognitionModalSlice,
	RecognitionModalState,
	RecognitionModalStateUnion,
} from '../../recognition/recognition-modal.slice';
import { GetConnectionPayload, getConnections } from '../ConnectionCRUD';
import {
	ConnectionFilterableFields,
	ConnectionSortableFields,
	ConnectionTypeEnumWithCa,
} from '../interfaces/connection.model';
import { VwConnection } from '../interfaces/vw_connection.model';

interface RowActionSelection {
	type: ConnectionTypeEnumWithCa;
	action: Exclude<ModalDisplayMode, ModalDisplayMode.Print>;
	record_id: number;
	record: VwConnection;
}

export interface Props {
	connectionType?: ConnectionTypeEnumWithCa[];
}

const fetchData = async (token: string, newPageNum: number, sort: SortModelItem[], filter: FilterModel) => {
	const parsedFilters: GridFilterItem<ConnectionFilterableFields>[] = [];
	toPairs(filter).map(([field, cfg]) => {
		let value = cfg.filter;
		const type = AgGridFilterTypeToSecchiOperators[(cfg.type as AgGridFilterModelTypeSupported)];

		if (cfg.filterType === 'date') {
			const from = parseDateObjectToNewFormat(new Date(cfg.dateFrom), DateFormatUsingDateFns.PerfectDate);
			const to = parseDateObjectToNewFormat(new Date(cfg.dateTo), DateFormatUsingDateFns.PerfectDate);

			switch (type) {
				case AgGridFilterTypeToSecchiOperators.lessThan:
				case AgGridFilterTypeToSecchiOperators.greaterThan:
					value = from;
					break;
				case AgGridFilterTypeToSecchiOperators.inRange:
					value = {
						from,
						to,
					};
					break;
				case AgGridFilterTypeToSecchiOperators.equals:
				case AgGridFilterTypeToSecchiOperators.contains:
					value = from;
					break;
				default:
					break;
			}
		}
		parsedFilters.push({
			// TODO: clean up - was getting error due to missing inRange value, don't need all the types/enums declared
			//  for this functionality
			operator: (type as unknown as QueryOperators),
			value,
			field: (field as unknown as ConnectionFilterableFields),
		});
	});
	const opts: GetConnectionPayload = {
		page: newPageNum,
		sort: convertGridSortToSecchiSort<ConnectionSortableFields>(sort),
		filters: parsedFilters,
		pageSize: 100,
	};
	return getConnections(token, opts);
};

export function ConnectionsGrid({connectionType}: Props) {
	const gridRef = useRef<AgGridReact<VwConnection>>(null);
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const containerStyle = useMemo(() => ({
		width: '100%',
		height: 'calc(100vh - 205px)',
	}), []);
	const gridStyle = useMemo(() => ({
		height: '100%',
		width: '100%',
	}), []);
	const dispatch = useDispatch();
	const [rowActionType, setRowActionType] = useState<RowActionSelection>();
	const [isLoading, setIsLoading] = useState(true);
	const coachingModalState = useSelector<RootState>(state => state.coachingModal) as CoachingModalStateUnion & Pick<CoachingModalState, 'showModal'>;
	const attendanceModalState = useSelector<RootState>(state => state.attendanceModal) as AttendanceModalStateUnion & Pick<AttendanceModalState, 'showModal'>;
	const disciplineModalState = useSelector<RootState>(state => state.disciplineModal) as DisciplineModalStateUnion & Pick<DisciplineModalState, 'showModal' | 'mutationRan'>;
	const aiReviewModalState = useSelector<RootState>(state => state.aiReviewModal) as AiReviewModalStateUnion & Pick<AiReviewModalState, 'showModal' | 'mutationRan'>;
	const recognitionModalState = useSelector<RootState>(state => state.recognitionModal) as RecognitionModalStateUnion & Pick<RecognitionModalState, 'showModal'>;
	const gridOptions: GridOptions = {
		paginationPageSizeSelector: false,
		datasource: {
			rowCount: undefined,
			getRows: async function(params: IGetRowsParams) {
				// https://www.ag-grid.com/javascript-data-grid/infinite-scrolling/#datasource-interface
				let pageNum = Math.round(params.endRow / 100);
				Logger('Get Rows', SkeDomain.Connection, {
					misc: {
						params,
						filter: params.filterModel,
					},
				});
				const filter = params.filterModel;
				// if (connectionType) {
				// 	gridRef.current?.api.setColumnFilterModel('connection_type_array', {
				// 		filter: connectionType?.[0],
				// 		filterType: 'text',
				// 	});
				// }
				if (!!connectionType && !filter.connection_type) {
					filter['connection_type_array'] = {
						filter: [
							connectionType,
						],
						filterType: 'text',
						type: 'contains',
					};
				}
				await fetchData(token, pageNum, params.sortModel, filter)
					.then(({ data }) => {
						const rowsThisPage = data.results.items;
						const lastRow = data.results.meta.pagination.totalItems;
						params.successCallback(rowsThisPage, lastRow);
					})
					.catch(err => {
						Logger(`Error getting ConnectionGrid data`, SkeDomain.Connection, {
							type: LogType.Error,
							error: err,
							misc: {
								params,
							},
						});
						toast.error(`Error getting data for Connection Grid`);
					})
					.finally(() => {
						setIsLoading(false);
					});
			},
		},
	};
	const [userTeams, setUserTeams] = useState<number[]>([]);

	useEffect(() => {
		setUserTeams(user.teams.map(tm => tm.id));
	}, [user]);

	useEffect(() => {
    if (gridRef.current) {
			isLoading ? gridRef.current?.api?.showLoadingOverlay() : gridRef.current?.api?.hideOverlay();
    }
  }, [isLoading]);

	const onGridReady = (params: GridReadyEvent) => {
    if (isLoading) {
      params.api.showLoadingOverlay();
    }
  };

	const areEditActionsAllowed = (connection: VwConnection): boolean => {
		if (!connection) return true;
		if (user?.roles?.includes(UserRoleEnum.SuperAdmin)) return true;
		if (!connection.team_id) return false;
		return userTeams.includes(connection.team_id);
	}

	const refetchAllData = () => {
		gridRef.current?.api?.refreshInfiniteCache();
	};

	useEffect(() => {
		switch (rowActionType?.type) {
			case ConnectionTypeEnumWithCa.Coaching:
				handleCoachingAction(rowActionType);
				break;
			case ConnectionTypeEnumWithCa.Recognition:
				handleRecognitionAction(rowActionType);
				break;
			case ConnectionTypeEnumWithCa.Attendance:
				handleAttendanceAction(rowActionType);
				break;
			case ConnectionTypeEnumWithCa.CorrectiveAction:
			case ConnectionTypeEnumWithCa.Discipline:
				handleDisciplineAction(rowActionType);
				break;
			case ConnectionTypeEnumWithCa.Review:
				handleAiReviewAction(rowActionType);
				break;
		}
	}, [rowActionType]);

	useEffect(() => {
		Logger(`coachingModalState change detected - set to show: ${coachingModalState.showModal}`, SkeDomain.Connection, {misc: {coachingModalState}});
		if (!coachingModalState.showModal) {
			if (rowActionType?.type === ConnectionTypeEnumWithCa.Coaching && rowActionType?.action !== ModalDisplayMode.Read) {
				Logger('rowActionType is coaching, resetting and reloading', SkeDomain.Connection, {
					misc: {
						coachingModalState,
					}
				});
				setRowActionType(undefined);
				refetchAllData();
			}
		}
	}, [coachingModalState]);

	useEffect(() => {
		Logger(`attendanceModalState change detected - set to show: ${attendanceModalState.showModal}`, SkeDomain.Connection, {misc: {attendanceModalState}});
		if (!attendanceModalState.showModal) {
			if (rowActionType?.type === ConnectionTypeEnumWithCa.Attendance && rowActionType?.action !== ModalDisplayMode.Read) {
				Logger(`rowActionType is attendance, resetting and reloading`, SkeDomain.Connection, {
					misc: {
						attendanceModalState,
					}
				});
				setRowActionType(undefined);
				refetchAllData();
			}
		}
	}, [attendanceModalState]);

	useEffect(() => {
		Logger(`disciplineModalState change detected - set to show: ${disciplineModalState.showModal}`, SkeDomain.Connection, {
			misc: {
				disciplineModalState,
				rowActionType,
			},
		});
		if (!disciplineModalState.showModal) {
			// skipping the mode check like the other connection types because only in CorrectiveActionModal can delete,
			// update happen even if it opens due to a "read" click
			if (!!rowActionType
				&& [ConnectionTypeEnumWithCa.Discipline, ConnectionTypeEnumWithCa.CorrectiveAction].includes(rowActionType.type)
				&& disciplineModalState.mutationRan) {
				Logger(`rowActionType is discipline and a mutation ran - resetting and reloading`, SkeDomain.Connection, {
					misc: {
						disciplineModalState,
					}
				});
				setRowActionType(undefined);
				refetchAllData();
			}
		}
	}, [disciplineModalState]);

	useEffect(() => {
		if (!recognitionModalState.showModal) {
			if (rowActionType?.type === ConnectionTypeEnumWithCa.Recognition && rowActionType?.action !== ModalDisplayMode.Read) {
				Logger(`rowActionType is recognition, resetting and reloading`, SkeDomain.Connection, {
					misc: {
						recognitionModalState,
					}
				});
				setRowActionType(undefined);
				refetchAllData();
			}
		}
	}, [recognitionModalState]);

	useEffect(() => {
		Logger(`aiReviewModalState change detected - set to show: ${aiReviewModalState.showModal}`, SkeDomain.Connection, {
			misc: {
				aiReviewModalState,
				rowActionType,
			},
		});
		if (!aiReviewModalState.showModal || aiReviewModalState.mutationRan) {
			if (rowActionType?.type === ConnectionTypeEnumWithCa.Review && aiReviewModalState.mutationRan) {
				Logger(`rowActionType is ai review and a mutation ran - resetting and reloading`, SkeDomain.Connection, {
					misc: {
						aiReviewModalState,
					}
				});
				setRowActionType(undefined);
				refetchAllData();
			}
		}
	}, [aiReviewModalState]);

	const handleRecognitionAction = (selection: RowActionSelection) => {
		gridRef.current?.api.deselectAll();
		switch (selection.action) {
			case ModalDisplayMode.Read: {
				dispatch(recognitionModalSlice.actions.show({
					mode: ModalDisplayMode.Read,
					recordId: selection.record_id,
				}));
				break;
			}
			case ModalDisplayMode.Update: {
				dispatch(recognitionModalSlice.actions.show({
					mode: ModalDisplayMode.Update,
					recordId: selection.record_id,
				}));
				break;
			}
			case ModalDisplayMode.Delete: {
				dispatch(recognitionModalSlice.actions.show({
					mode: ModalDisplayMode.Delete,
					recordId: selection.record_id,
				}));
				break;
			}
			default:
				break;
		}
	};
	const handleCoachingAction = (selection: RowActionSelection) => {
		gridRef.current?.api.deselectAll();
		switch (selection.action) {
			case ModalDisplayMode.Read: {
				dispatch(coachingModalSlice.actions.show({
					mode: ModalDisplayMode.Read,
					recordId: selection.record_id,
				}));
				break;
			}
			case ModalDisplayMode.Update: {
				dispatch(coachingModalSlice.actions.show({
					mode: ModalDisplayMode.Update,
					recordId: selection.record_id,
				}));
				break;
			}
			case ModalDisplayMode.Delete: {
				dispatch(coachingModalSlice.actions.show({
					mode: ModalDisplayMode.Delete,
					recordId: selection.record_id,
				}));
				break;
			}
			default:
				break;
		}
	};

	const handleAttendanceAction = (selection: RowActionSelection) => {
		gridRef.current?.api.deselectAll();
		switch (selection.action) {
			case ModalDisplayMode.Read: {
				dispatch(attendanceModalSlice.actions.show({
					mode: ModalDisplayMode.Read,
					recordId: selection.record_id,
				}));
				break;
			}
			case ModalDisplayMode.Update: {
				dispatch(attendanceModalSlice.actions.show({
					mode: ModalDisplayMode.Update,
					recordId: selection.record_id,
				}));
				break;
			}
			case ModalDisplayMode.Delete: {
				dispatch(attendanceModalSlice.actions.show({
					mode: ModalDisplayMode.Delete,
					recordId: selection.record_id,
				}));
				break;
			}
			default:
				break;
		}
	};
	const handleDisciplineAction = (selection: RowActionSelection) => {
		gridRef.current?.api.deselectAll();
		switch (selection.action) {
			case ModalDisplayMode.Update:
			case ModalDisplayMode.Delete:
			case ModalDisplayMode.Read:
				dispatch(disciplineModalSlice.actions.show({
					mode: selection.action,
					recordId: selection.record_id,
				}));
				break;
			default:
				break;
		}
	};

		const handleAiReviewAction = (selection: RowActionSelection) => {
			gridRef.current?.api.deselectAll();
			switch (selection.action) {
				case ModalDisplayMode.Update:
				case ModalDisplayMode.Delete:
				case ModalDisplayMode.Read:
					dispatch(aiReviewModalSlice.actions.show({
						token: token,
						//@ts-ignore
						employeeId: selection.record.employee_id,
						mode: selection.action,
						//@ts-ignore
						record: selection.record,
						recordId: selection.record_id,
					}));
					break;
				default:
					break;
			}
		};

	const columnDefs: ColDef[] = [
		{
			field: 'employee_name',
			headerName: 'Employee',
			enableRowGroup: true,
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'date',
			headerName: 'Date',
			enableRowGroup: true,
			type: 'date',
			suppressHeaderMenuButton: true,
			filter: 'agDateColumnFilter',
			filterParams: {
				filterOptions: [
					'equals',
					'inRange',
					'lessThan',
					'greaterThan',
				],
			},
		},
		{
			field: 'connection_type',
			headerName: 'Connection Type',
			suppressHeaderMenuButton: true,
			// for connectionType handling on tabs to pre-filter
			filter: (connectionType?.length) ? false : 'agTextColumnFilter',
			filterParams: (connectionType?.length) ? false : {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'team_name',
			headerName: 'Team',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'creator_name',
			headerName: 'Creator',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'category_name',
			headerName: 'Category',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'step_name',
			headerName: 'Step',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			suppressHeaderMenuButton: true,
			filter: 'agTextColumnFilter',
			filterParams: {
				filterOptions: [
					'contains',
					'equals',
				],
			},
		},
		{
			field: 'actions',
			headerName: 'Actions',
			suppressHeaderMenuButton: true,
			sortable: false,
			width: 120,
			resizable: false,
			pinned: 'right',
			cellRenderer: (params: any) => {
				const isEditable = areEditActionsAllowed(params.data);
				if ([ConnectionTypeEnumWithCa.Review, ConnectionTypeEnumWithCa.CorrectiveAction].includes(params?.data?.connection_type)) {
					return (
						<div
							className="text-center"
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
							}}>
							<Button
								variant="outline-primary"
								size="sm"
								className="btn-icon ms-2 text-muted"
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									gridRef.current?.api.deselectAll();
									const record = params.data;
									setRowActionType({
										type: record.connection_type,
										record,
										record_id: record.id,
										action: ModalDisplayMode.Read,
									});
								}}
							>
								<Icon
									type={Binoculars.iconType}
									size='sm'
									icon={Binoculars} />
							</Button>
						</div>
					)
				}
				return <>
					<div
						className="text-center"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
						}}>
						{isEditable && (
							<>
								<Button
									variant="outline-primary"
									size="sm"
									className="btn-icon ms-2"
									onClick={(event) => {
										event.preventDefault();
										event.stopPropagation();
										gridRef.current?.api.deselectAll();
										const record = params.data;
										setRowActionType({
											type: record.connection_type,
											record_id: record.id,
											record,
											action: ModalDisplayMode.Update,
										});
									}}
								>
									<KTSVG
										path="/media/icons/duotune/general/gen055.svg"
										className="svg-icon-3"
									/>
								</Button>
								<Button
									onClick={(event) => {
										event.preventDefault();
										event.stopPropagation();
										gridRef.current?.api.deselectAll();
										const record = params.data;
										setRowActionType({
											type: record.connection_type,
											record,
											record_id: params.data.id,
											action: ModalDisplayMode.Delete,
										});
									}}
									variant="outline-danger"
									size="sm"
									className="btn-icon ms-2"
								>
									<KTSVG
										path="/media/icons/duotune/general/gen027.svg"
										className="svg-icon-3"
									/>
								</Button>
							</>)}
						{!isEditable && (
							<>
								<Button
									variant="outline-primary"
									size="sm"
									className="btn-icon ms-2 text-muted"
									onClick={(event) => {
										event.preventDefault();
										event.stopPropagation();
										gridRef.current?.api.deselectAll();
										const record = params.data;
										setRowActionType({
											type: record.connection_type,
											record_id: record.id,
											record,
											action: ModalDisplayMode.Read,
										});
									}}
								>
									<Icon
										type={Binoculars.iconType}
										size='sm'
										icon={Binoculars} />
								</Button>
							</>
						)}
				</div>
				</>;
			},
		},
	];

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			editable: false,
			enableRowGroup: true,
			enablePivot: true,
			enableValue: true,
			filter: true,
			floatingFilter: true,
			flex: 1,
			minWidth: 50,
			enableCellChangeFlash: true,
		};
	}, []);

	const rowSelected = useCallback(() => {
		const selectedRows = gridRef.current!.api.getSelectedRows();
		const row = selectedRows[0];
		if (!row) {
			return;
		}
		setRowActionType({
			type: row.connection_type,
			record: row,
			record_id: row.id,
			action: ModalDisplayMode.Read,
		});
	}, []);

	return (
		<>
			{/*<PageTitle breadcrumbs={[]}>Connections</PageTitle>*/}

			<div style={containerStyle}>
				<div
					style={gridStyle}
					className={
						'ag-theme-quartz ag-theme-acmecorp'
					}
				>
					<AgGridReact<VwConnection>
						ref={gridRef}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						rowModelType={'infinite'}
						rowSelection={'single'}
						cacheBlockSize={100}
						cacheOverflowSize={2}
						maxConcurrentDatasourceRequests={2}
						maxBlocksInCache={2}
						pagination={true}
						gridOptions={gridOptions}
						onRowClicked={rowSelected}
						loadingOverlayComponent={SkeSpinner}
						loadingOverlayComponentParams={{
							label: 'Getting Data...',
						}}
						onGridReady={onGridReady}
					/>
				</div>
			</div>
		</>
	);
}
