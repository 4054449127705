import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Icon } from '../../../../app/components/icons/Icon';
import { Building } from '../../../../app/components/icons/IconList';
import { UserModel } from '../../../../app/models';
import { RootState } from '../../../../setup';
import { useLayout } from '../../core'
import { KTSVG } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
import './AsideDefault.scss'
import darkLogo from '../../../../images/logos/secchi_dark_logo.png'
import lightLogo from '../../../../images/logos/secchi_light_logo.png'

interface Props {
  onboarding?: boolean;
}

export function AsideDefault({ onboarding }: Props) {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { config, classes } = useLayout()
  const { aside } = config

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >

      {onboarding && <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000
      }}></div>}

      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        <div className="d-flex flex-column">
          {aside.theme === 'dark' && (
            <Link to='/dashboard'>
              <img
                alt='Logo'
                className='h-55px logo'
                src={darkLogo}
              />
            </Link>
          )}
          {aside.theme === 'light' && (
            <Link to='/dashboard'>
              <img
                alt='Logo'
                className='h-55px logo'
                src={lightLogo}
              />
            </Link>
          )}
          <span className="text-center">
               <Icon
                type="svg"
                size="sm"
                classes="text-muted"
                icon={Building}
                use="decorative"
              />
            {user?.company?.name}
          </span>
        </div>

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
      </div>

      <div className="aside_logo_divider_wrapper">
        <div className="aside_logo_divider"></div>
      </div>

      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      <div className='aside-footer flex-column-auto' id='kt_aside_footer'>
        {/*<div className="alert alert-success d-flex align-items-center justify-content-center p-5">*/}

        {/*  <div className="d-flex flex-column">*/}
        {/*    <span className="mb-3 text-center">New Update Deployed <br /> 12-15-22</span>*/}
        {/*    <a*/}
        {/*      target='_blank'*/}
        {/*      className='btn btn-sm btn-custom btn-primary w-100'*/}
        {/*      href='https://drive.google.com/file/d/1ynEWdBX-pMbsAv9Rs9rIqy5Pkv1OPkAc/view?usp=share_link'*/}
        {/*      data-bs-toggle='tooltip'*/}
        {/*      data-bs-trigger='hover'*/}
        {/*      data-bs-dismiss-='click'*/}
        {/*      title='New Update Deployed 1-20-23'*/}
        {/*    >*/}
        {/*      <span className='btn-label'>Learn More</span>*/}
        {/*      <span className='svg-icon btn-icon svg-icon-2'>*/}
        {/*        <KTSVG path='/media/icons/duotune/general/gen005.svg' />*/}
        {/*      </span>*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
