import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Icon } from '../../../components/icons/Icon';
import { Pencil } from '../../../components/icons/IconList';
import { EmployeeType } from '../../../types/EmployeeType'
import { toAbsoluteUrl } from '../../../../_library/helpers'
import { UserModel } from '../../../models'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import moment from 'moment'
import { updateEmployeeNotes } from '../../../CRUD/TeamCRUD'
import { SkeTextInlineEditor } from '../../../../common/components/text-inline-editor';

// Added for FMLA
import { SortDateEnum } from '../../../types/SortDateEnum';
import { SHORT_DATE } from '../../../modules/date/DateFormat.const';
import { useParams } from 'react-router';
import { AttendanceType } from '../../../types/AttendanceType';
import { ConnectionType, ConnectionTypeEnum, ConnectionUnion } from '../../../types/ConnectionType';
import {
	getEmployeeAttendances,
} from '../../../CRUD/TeamCRUD';
import { AttendanceGetParamsModel } from '../../../modules/attendance/models/AttendanceGetParamsModel';
import { EmployeeEditModal } from '../../../../features/employee/modals/EmployeeEditModal';
import {get} from 'lodash'
import {useNavigate} from 'react-router-dom'
import {Button} from 'react-bootstrap'

type RouteParams = {
	teamId: string;
	id: string;
}

interface Props {
	employee: EmployeeType;
	refreshEmployeeData: () => void;
}

const TeamCard: React.FC<Props> = ({ employee, refreshEmployeeData }) => {
	const {
		teamId,
		id,
	} = useParams<RouteParams>();

	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;

	let currentTeam = employee && employee.teams ? employee.teams[0] : null
	const [currentStreak, setCurrentStreak] = useState<number>(0);
	const [longestStreak, setLongestStreak] = useState<number>(0);
	const [isEditingNotes, setIsEditingNotes] = useState<boolean>(false);
	const [isFMLA, setIsFMLA] = useState<boolean>(false);

	const navigate = useNavigate();

	// Added for FMLA - Look at attendance reason to determine FMLA
	// for display on the employee profile

	const [fmlaHoursLeft, setfmlaHoursLeft] = useState<number>(0);
	// grabbing 1 month out for Corrective Actions scheduled in the future
	const [fmlaEndDate, setfmlaEndDate] = useState<string>('');

	const [ showNewEditModal, setShowNewEditModal] = useState<boolean>(false)

	const handleTeamMemberEditOnClose = (action: boolean) => {
		setShowNewEditModal(action);
	};

	const getAttendances = async (fromDate: Date, toDate: Date): Promise<AttendanceType[]> => {
		const opts: AttendanceGetParamsModel = {
			fromDate: moment(fromDate).format(SHORT_DATE),
			toDate: moment(toDate).format(SHORT_DATE),
			sort: SortDateEnum.Asc,
			includeFuture: 'true',
			limit: 100,
		};
		if (!id) {
			throw new Error('No employee id set');
		}
		try {
			//Load FMLA details
			if (employee.fmla_hours_left) {
				setfmlaHoursLeft(employee.fmla_hours_left);
			}
			if (employee.fmla_return_date) {
				setIsFMLA(true);
				setfmlaEndDate(employee.fmla_return_date.toString());
			}
			const { data } = await getEmployeeAttendances(+id, token, opts);
			return data.items;
		} catch (err: any) {
			return [];
		}

	};


	const handleSaveNotes = (newNotes: string) => {
		updateEmployeeNotes(employee.id, newNotes, token)
			.then(() => {
				setIsEditingNotes(false);
				refreshEmployeeData();
			})
			.catch(error => {
				const friendlyMessage = error?.response?.data?.message || error;
				toast.error(friendlyMessage);
			});
	};

	const parseConnectionsForCalendars = (item: ConnectionUnion) => {
		let type: ConnectionType = item.connectionType!;
		let itemReason = (item as AttendanceType).reason;

		if (type === 'ATTENDANCE' && itemReason.isExcused) {
			type = ConnectionTypeEnum.Excused;
		}
		return {
			type,
			// needed for monthly calendar
			yearMonth: item.date?.slice(0, 7),
			date: item.date,
			// both needed for yearly calendar
			startDate: moment(item.date).toDate(),
			endDate: moment(item.date).toDate(),
			reason: itemReason
		};
	};

	const loadConnectionsForHistory = async () => {

		const att = await getAttendances(moment().subtract(1, 'year').toDate(), moment().add(1, 'month').toDate());
		const history = att.map(parseConnectionsForCalendars);

	}

	useEffect(() => {
		let tempLongestStreak = 0
		employee?.streaks?.forEach((streak) => {
			if (streak.duration > tempLongestStreak) {
				tempLongestStreak = streak.duration
			}
			if (!streak.is_ended) {
				setCurrentStreak(streak.duration)
			}
		})
		setLongestStreak(tempLongestStreak)
		loadConnectionsForHistory()


	}, [employee])

	return (
		<>
			<div className='flex-column mb-10 col-sm-12 col-md-6 col-lg-5 col-xl-2'>
				<div className='card mb-5 mb-xl-8'>
					<div className='card-body m-3 p-3'>
						<div className='d-flex flex-center flex-column py-5'>
							<div className='symbol symbol-100px symbol-circle mb-7'>
								<img
									src={
										!employee.avatarUrl ||
											employee.avatarUrl?.includes('media/icons/duotune/general/create-team-icon')
											? toAbsoluteUrl('/media/avatars/blank.png')
											: employee.avatarUrl
									}
									alt=''
								/>
							</div>
							{employee.isTerminated &&(
								<div className='fs-3 text-danger fw-bolder mb-3'>
									TERMINATED
								</div>
							)}
							<div className='fs-3 text-gray-800 fw-bolder mb-3'>
								{employee.firstName} {employee.lastName}
							</div>
							<div className='mb-9 mt-5'>
								<Button
									variant={'link'}
									onClick={()=>navigate(`/teams/show/${currentTeam?.id}/overview`)}
								>
									{currentTeam?.name}
								</Button>
							</div>
							<ul className='list-unstyled'>
								<li className='mb-1'>
									<div className='badge badge-lg badge-light-primary text-muted'>
										Last Connect: {employee.lastConnectDate || 'None'}
									</div>
								</li>
								<li className='mb-1'>
									<div className='badge badge-lg badge-light-success text-muted'>
										Recognition (30 Days): {employee.recognitionLast30}
									</div>
								</li>
								<li className='mb-1'>
									<div className='badge badge-lg badge-light-success text-muted'>
										Recognition (1 Year): {employee.recognitionLastYear}
									</div>
								</li>
								<li className='mb-1 accordion' id='attendanceAccordion'>
									<div className='accordion-item'>
										<h2 className='accordion-header' id='attendanceHeading'>
											<button
												className='accordion-button btn mr-1'
												type='button'
												data-bs-toggle='collapse'
												data-bs-target='#attendanceCollapse'
												aria-expanded='true'
												aria-controls='attendanceCollapse'
											>
												Attendance
											</button>
										</h2>
										<div
											id='attendanceCollapse'
											className='accordion-collapse collapse mx-0 px-0'
											aria-labelledby='attendanceHeading'
											data-bs-parent='#attendanceAccordion'
										>
											<div className='accordion-body justify-content-start flex-column'>
												<ul className='list-unstyled m-0 p-0'>
													<li className='mb-1 p-0 mx-0 px-0'>
														<div className='badge badge-lg badge-light-warning text-muted'>
															Points: {employee.currentAttendancePoints}
														</div>
													</li>
													{!!get(user, 'company.settings.connection.attendance.tracking_start', null) &&
														<>
															<li className="mb-1 p-0 mx-0 px-0">
																<div className="badge badge-lg badge-light-warning text-muted">
																	Current Streak: {currentStreak} days
																</div>
															</li>
															<li className="mb-1 p-0 mx-0 px-0">
																<div className="badge badge-lg badge-light-warning text-muted">
																	Longest Streak: {longestStreak} days
																</div>
															</li>
														</>
													}

													{isFMLA && (
														<>
															<li className="mb-1 p-0 mx-0 px-0">
																<span className="fs-6">
																	FMLA
																</span>
															</li>
															<li className="mb-1 p-0 mx-0 px-0">
																<div className="badge badge-lg badge-light-warning text-muted">
																	Hours Left: {fmlaHoursLeft}
																</div>
															</li>
															<li className="mb-1 p-0 mx-0 px-0">
																<div className="badge badge-lg badge-light-warning text-muted">
																	Certification Date: {fmlaEndDate}
																</div>
															</li>
														</>
													)}
												</ul>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div className='pb-5 fs-6'>
							<span className='fw-bolder mt-5 d-block'>Employee ID</span>
							<span className='text-gray-600 d-block'>{employee.employeeId}</span>
							<div className="mt-5">
								<span className="fw-bolder mt-5 d-block cursor-pointer"
									onClick={() => setIsEditingNotes(true)}>
									Notes
									<Icon classes="text-info ms-1"
										type={Pencil.iconType}
										size="sm"
										icon={Pencil} />
								</span>
								<SkeTextInlineEditor type="textarea"
									initialValue={employee.misc?.details.notes || ''}
									isEditing={isEditingNotes}
									onSave={(newNotes) => {
										if (newNotes !== employee.misc?.details.notes) {
											return handleSaveNotes(newNotes);
										}
										return setIsEditingNotes(false);
									}}
									onClick={() => setIsEditingNotes(true)}
									onCancel={() => setIsEditingNotes(false)}
								/>
							</div>
							<span className='fw-bolder mt-5 d-block'>Phone</span>
							<div className='text-gray-600 d-block'>
								{!employee.phone && (
									<span className='text-gray-600'>None</span>
								)}
								{!!employee.phone && (
									<a href='#' className='text-gray-600 text-hover-primary'>
										{employee.phone || 'None'}
									</a>
								)}
							</div>
							<span className='fw-bolder mt-5 d-block'>Temp/Agency</span>
							<span className='text-gray-600 d-block'>{employee.isAgency ? 'Yes' : 'No'}</span>
							<span className='fw-bolder mt-5 d-block'>Birthday</span>
							<span className='text-gray-600 d-block'>
								{moment(employee.birthDate, 'YYYY-MM-Dd').format('MMMM Do')}
							</span>
							<span className='fw-bolder mt-5 d-block'>Hire Date</span>
							<span className='text-gray-600 d-block'>{employee.hireDate}</span>
							<span className='fw-bolder mt-5 d-block'>Status</span>
							<span className='text-gray-600 d-block'>{employee.isTerminated ? 'Terminated' : 'Active'}</span>
							{employee.isTerminated && (
								<>
									<div className='fw-bolder mt-5 d-block'>Terminated Date</div>
									<div className='text-gray-600 d-block'>{employee.terminatedDate || 'Not set'}</div>
								</>
							)}

							{!!user.roles?.includes('SuperAdmin') && (
								<div className='pt-3 d-flex justify-content-between'>
									<button
										className='btn btn-sm btn-light-primary'
										onClick={()=>setShowNewEditModal(true)}
									>
										Edit
									</button>
									<EmployeeEditModal
										show={showNewEditModal}
										onClose={handleTeamMemberEditOnClose}
										employee={employee}
										refreshEmployeeData={refreshEmployeeData}
									/>
									<button
										className='btn btn-sm btn-light-warning'
										data-bs-toggle='modal'
										data-bs-target='#change_team_modal'
									>
										Change Team
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export { TeamCard }
