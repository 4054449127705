import clsx from 'clsx';
import React from 'react';
import { EmployeeBadge } from '../../../components/EmployeeBadge';
import { CloseButtonIcon } from '../../../components/icons/CloseButtonIcon';
import { AttendanceType } from '../../../types/AttendanceType';
import { useDispatch } from 'react-redux';
import { attendanceModalSlice } from '../../../../features/attendance/attendance-modal.slice';

interface Props {
	attendance: AttendanceType | null;
	modalId?: string;
	onClose?: () => void;
}

export function AttendanceViewModal({
																			attendance,
																			modalId = 'attendance_view_modal',
																			onClose
}: Props) {
	const dispatch = useDispatch();
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content p-3">
					<div className="modal-header d-flex flex-wrap-reverse justify-content-between align-items-center">

						<div className="col-12 col-sm-5">
							<EmployeeBadge
								employee={attendance?.employee}
								team={attendance?.team} />
						</div>

						<h1>Attendance</h1>
						<button
							onClick={()=>{
								if (onClose) {
									onClose();
								}
								dispatch(attendanceModalSlice.actions.hide());
							}}
							className="btn btn-icon"
							data-bs-dismiss="modal"
						>
							<CloseButtonIcon />
						</button>
					</div>

					<div className="modal-body mx-0 p-0 pt-3">
						<div className="d-flex flex-wrap flex-grow-1">
							<div className="row border-bottom border-bottom-1">
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Date</span>
									<span className="d-block">{attendance?.date}</span>
								</div>
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Occurrence</span>
									<span className="d-block">{attendance?.occurrence}</span>
								</div>
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Reason</span>
									<span className="d-block">{attendance?.reason?.content}</span>
								</div>
								{!attendance?.reason?.content.includes('FMLA') && (
									<div className="col-5 col-sm-4 mx-2 pb-3">
										<span className="fw-bolder d-block">Points</span>
										<span className="d-block">{attendance?.pointsAssigned}</span>
									</div>
								)}
								{attendance?.reason?.content.includes('FMLA') && (
									<div className="col-5 col-sm-4 mx-2 pb-3">
										<span className="fw-bolder d-block">Hours</span>
										<span className="d-block">{attendance?.fmla_hours}</span>
									</div>
								)}
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Excusable</span>
									<span className="d-block">{attendance?.reason?.isExcused ? 'Excused' : 'Unexcused'}</span>
								</div>
								<div className="col-5 col-sm-4 mx-2 pb-3">
									<span className="fw-bolder d-block">Created By</span>
									<span className="d-block">{attendance?.creator?.firstName} {attendance?.creator?.lastName}</span>
								</div>
							</div>

							<div className="row mx-2 pt-3">
								<span className="fs-2 fw-bolder d-block">Detail</span>
								<p
									className={clsx({'fst-italic': !attendance?.supervisorNote})}>
									{attendance?.supervisorNote || 'No details provided'}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
